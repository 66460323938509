<script setup lang="ts">
import { ref, reactive, computed, watch, toRef, onUnmounted, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useQuestionsStore } from "@/stores/questions";
import QuestionTextInput from "@/components/question-types/QuestionTextInput.vue";
import QuestionDate from "@/components/question-types/QuestionDate.vue";
import QuestionYesNo from "@/components/question-types/QuestionYesNo.vue";
import QuestionRadio from "@/components/question-types/QuestionRadio.vue";
import QuestionCheckbox from "@/components/question-types/QuestionCheckbox.vue";
import QuestionTextPredictions from "@/components/question-types/QuestionTextPredictions.vue";
import QuestionMultiple from "@/components/question-types/QuestionMultiple.vue";
import {
  type SubSectionKey,
  type QuestionCheckbox as QuestionCheckboxType,
  type QuestionRadio as QuestionRadioType,
  type QuestionMultiple as QuestionMultipleType,
  type QuestionKey,
  QuestionType,
  LifestyleQuestionKey,
  SectionKey,
  USA_Height,
} from "@/types/questions";
import QuestionScreen from "@/layout/QuestionScreen.vue";
import WhyWeAsk from "@/components/ui/WhyWeAsk.vue";
import { usePhraseTranslationShortcuts } from "@/composables/usePhraseTranslationShortcuts";
import {
  validateTextInput,
  validateBirthDate,
  validateNumber,
  validateCheckbox,
  validateHeightFeet,
  validateHeightInches,
} from "@/utils/validations";
import { useI18n } from "vue-i18n";
import type { Medication } from "@/types/apiTypes";
import PrivacyConsent from "@/components/ui/PrivacyConsent.vue";
import { useCrm } from "@/composables/useCrm";
import { useMainStore } from "@/stores/main";
import QuestionHeightUSA from "./question-types/QuestionHeightUSA.vue";
import { useUSAQuestions } from "@/composables/useUSAQuestions";

const { t } = useI18n();

const mainStore = useMainStore();
const questStore = useQuestionsStore();
const route = useRoute();
const { isConsentSelectedIfEmailQuestion } = useCrm();
const { activeQuestion, allAnswers: storeAnswers } = storeToRefs(questStore);

const subSection = computed<SubSectionKey>(() => activeQuestion.value.subSection);
const { phraseNrByRouteName, phraseBySubSection } = usePhraseTranslationShortcuts(subSection);

const triedToNextAtLeastOnce = ref(false);

// ----- Local Answer -----
const localAnswer: any = reactive({ value: "", error: false }); // TODO change that any type
const updateLocalAnswer = (key: QuestionKey) => {
  localAnswer.value = storeAnswers.value[key].value;
};
// ----- Local Answer -----

watch(
  route,
  (newRoute) => {
    const routeName = route.name as QuestionKey;

    if (newRoute.meta.qKey) {
      questStore.setActiveQuestionKey(routeName);
      questStore.setNextActiveQuestions();
      questStore.setActiveSectionKey(route.meta.sKey as SectionKey);
      updateLocalAnswer(routeName as QuestionKey);
      scrollTo({ top: 0 });
    }
  },
  { immediate: true }
);

const questionTypeToUI: { [key in QuestionType]?: any } = {
  text: QuestionTextInput,
  number: QuestionTextInput,
  number_height_usa: QuestionHeightUSA,
  date: QuestionDate,
  radio: QuestionRadio,
  yes_no: QuestionYesNo,
  checkbox: QuestionCheckbox,
  text_pred: QuestionTextPredictions,
  multiple: QuestionMultiple,
};

const questComponent = computed<any>(() => {
  return questionTypeToUI[activeQuestion.value.type];
});

const showNoBtn = computed<boolean>(() => {
  if (route.meta.qKey === LifestyleQuestionKey.MEDS) return true;
  return false;
});

// ----- Validations -----

const isQuestionErrored = (val: boolean) => {
  return val && triedToNextAtLeastOnce.value;
};

const validationError = computed<boolean | { [key in USA_Height]: boolean }>(() => {
  switch (activeQuestion.value.type) {
    case QuestionType.TEXT:
      return !validateTextInput(localAnswer.value, activeQuestion.value.key);
    case QuestionType.DATE:
      return !validateBirthDate(localAnswer.value, mainStore.isLocationUSA);
    case QuestionType.NUMBER:
    case QuestionType.RADIO:
    case QuestionType.YES_NO:
      return !validateNumber(localAnswer.value, activeQuestion.value.key, mainStore.isLocationUSA);
    case QuestionType.CHECKBOX:
    case QuestionType.MULTIPLE:
      return !validateCheckbox(localAnswer.value, activeQuestion.value as QuestionCheckboxType);
    case QuestionType.NUMBER_HEIGHT_USA:
      return {
        feet: !validateHeightFeet(localAnswer.value),
        inches: !validateHeightInches(localAnswer.value),
      };
    default:
      return false as never;
  }
});

const isQuestionValid = computed<boolean>(() => {
  if (usaIsHeightQuestion.value) return usaHeightQuestionValid.value;
  return !validationError.value;
});
// ----- Validations -----

const activeQuestComponentData = computed<any>(() => {
  if (questComponent.value === QuestionHeightUSA) {
    return {
      props: {
        propKey: activeQuestion.value.key,
        value: localAnswer.value,
        errors: usaHeightQuestionErrors(),
      },
      on: {
        change: (e: { val: string; type: USA_Height }) => usaUpdateHeight(e),
      },
    };
  } else if (questComponent.value === QuestionTextInput) {
    return {
      props: {
        key: activeQuestion.value.key,
        value: localAnswer.value,
        errored: isQuestionErrored(validationError.value as boolean),
        usaLabel: usaVariation.value ? "TEXT" : "",
        activeQuestionKey: activeQuestion.value.key,
      },
      on: {
        change: (e: string) => updateTextInput(e),
      },
    };
  } else if (questComponent.value === QuestionDate) {
    return {
      props: {
        key: activeQuestion.value.key,
        value: localAnswer.value || "",
        errored: isQuestionErrored(validationError.value as boolean),
        usaVariation: usaVariation.value,
      },
      on: {
        change: (date: string) => updateDate(date),
      },
    };
  } else if (questComponent.value === QuestionRadio) {
    return {
      props: {
        key: activeQuestion.value.key,
        allValues: (activeQuestion.value as QuestionRadioType).allValues,
        selectedValue: localAnswer.value,
      },
      on: {
        change: (id: number) => updateRadio(id),
      },
    };
  } else if (questComponent.value === QuestionYesNo) {
    return {
      props: {
        key: activeQuestion.value.key,
        selectedValue: localAnswer.value,
      },
      on: {
        change: (id: number) => updateRadio(id),
      },
    };
  } else if (questComponent.value === QuestionCheckbox) {
    return {
      props: {
        key: activeQuestion.value.key,
        allValues: (activeQuestion.value as QuestionCheckboxType).allValues,
        selectedValues: localAnswer.value || [],
        max: (activeQuestion.value as QuestionMultipleType).max,
      },
      on: {
        change: (ids: number[]) =>
          (() => {
            updateCheckbox(ids);
          })(),
      },
    };
  } else if (questComponent.value === QuestionMultiple) {
    return {
      props: {
        key: activeQuestion.value.key,
        allValues: (activeQuestion.value as QuestionMultipleType).allValues,
        selectedValues: localAnswer.value,
        max: (activeQuestion.value as QuestionMultipleType).max,
      },
      on: {
        change: (ids: number[]) => updateCheckbox(ids),
      },
    };
  } else if (questComponent.value === QuestionTextPredictions) {
    return {
      props: {
        key: activeQuestion.value.key,
        selectedValues: localAnswer.value,
      },
      on: {
        change: (meds: Medication[]) => updateTextPredictions(meds),
      },
    };
  }
  return { props: {}, on: {} };
});

const updateTextInput = (value: string) => {
  localAnswer.value = value;
};

const updateDate = (value: string) => {
  localAnswer.value = value;
};

const updateRadio = (value: number) => {
  localAnswer.value = value;
};

const updateCheckbox = (value: number[]) => {
  localAnswer.value = value;
};

const updateTextPredictions = (meds: Medication[]) => {
  localAnswer.value = meds.map((m) => {
    return { ...m };
  });
};

// ----- CSS -----
const uiElementWrapperClass = computed<Record<string, boolean>>(() => {
  return {
    "qc-all": true,
    "qc-text-input": [QuestionType.TEXT, QuestionType.NUMBER].includes(activeQuestion.value.type),
    "qc-date": activeQuestion.value.type === QuestionType.DATE,
    "qc-multiple": activeQuestion.value.type === QuestionType.MULTIPLE,
    "qc-text-pred": activeQuestion.value.type === QuestionType.TEXT_PRED,
  };
});
// ----- CSS -----

// ----- Navigation -----
const navigate = (to: "BACK" | "FORWARD" | "FORWARD_EMPTY") => {
  if (to === "BACK") return questStore.goBack();
  if (to === "FORWARD_EMPTY") localAnswer.value = [];

  triedToNextAtLeastOnce.value = true;
  const isValid = isQuestionValid.value;
  if (!isConsentSelectedIfEmailQuestion(activeQuestion.value)) return;
  if (!isValid) return;
  triedToNextAtLeastOnce.value = false;

  questStore.updateAnswer(activeQuestion.value.key, localAnswer.value);
  return questStore.goNext();
};

// ----- Navigation -----

// ----- USA -----
const { usaIsHeightQuestion, usaHeightQuestionValid, usaUpdateHeight, usaHeightQuestionErrors, usaVariation } =
  useUSAQuestions(validationError, toRef(localAnswer, "value"), isQuestionErrored);
// ----- USA -----

onMounted(() => {
  window.addEventListener("beforeunload", questStore.saveDataToStorage);
});
onUnmounted(() => {
  window.removeEventListener("beforeunload", questStore.saveDataToStorage);
});
</script>
<template>
  <QuestionScreen
    :valid="isQuestionValid"
    :showNoBtn="showNoBtn"
    @navigate="navigate"
    :phraseKeys="{ qKey: phraseNrByRouteName, ssKey: phraseBySubSection }"
  >
    <template v-slot:hint>
      <div v-if="activeQuestion.type === QuestionType.CHECKBOX" class="question-hint">
        {{ t("HINT.MORE_ANSWERS") }}
      </div>
      <div v-if="activeQuestion.hasHint" class="question-hint">{{ t(`QUESTION_${phraseNrByRouteName}.HINT`) }}</div>
      <div v-if="activeQuestion.key === LifestyleQuestionKey.EMAIL" class="unique-email">
        {{ $t("PAY.INF.EMAIL.UNIQUE") }}
      </div>
    </template>
    <template v-slot:questComp>
      <div :class="uiElementWrapperClass">
        <component
          :is="questComponent"
          v-bind="activeQuestComponentData.props"
          v-on="activeQuestComponentData.on"
        ></component>
        <PrivacyConsent
          v-if="activeQuestion.key === LifestyleQuestionKey.EMAIL"
          :consentSelected="questStore.lifestyleStore.marketingConsentSelected"
          phraseKey="EMAIL.MARKETING.FULL"
          :errored="!questStore.lifestyleStore.marketingConsentSelected && triedToNextAtLeastOnce"
          @toggleConsent="questStore.lifestyleStore.toggleMarketingConsent"
        ></PrivacyConsent>
      </div>
    </template>
    <template v-slot:why-modal>
      <WhyWeAsk
        v-if="activeQuestion.hasWhyModal"
        :phraseKey="phraseNrByRouteName"
        :whyType="activeQuestion.whyType"
      ></WhyWeAsk>
    </template>
  </QuestionScreen>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/layout.css";

.qc-all {
  width: 100%;
}
.qc-text-pred {
  height: 100%;
}
.unique-email {
  max-width: 80%;
  margin: 0.5em auto 0 auto;
  font-size: 0.8rem;
}
@media (--tablet-width) {
  .unique-email {
    margin: 0.3em auto 0 auto;
    margin: 0 auto;
    max-width: 100%;
    line-height: 1rem;
    font-size: 0.75rem;
  }
}
</style>
