export default {
  mounted(el: HTMLElement, binding: any) {
    // @ts-ignore
    el.clickOutsideEvent = (event: any) => {
      if (!(el === event.target || el.contains(event.target))) {
        //* if directive arg is clicked then ignore it
        if (binding.arg && binding.arg.contains(event.target)) return;
        binding.value();
      }
    };
    // @ts-ignore
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el: HTMLElement) {
    // @ts-ignore
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};
