import axios from "axios";
import type { AxiosInstance } from "axios";

const axiosInstance = axios.create({
  headers: {
    "Client-App": import.meta.env.VITE_CLIENT_APP,
  },
}) as AxiosInstance;

const setAuthToken = (token: string) => {
  if (token) {
    axiosInstance.defaults.headers.common["Auth-Token"] = token;
  } else {
    delete axiosInstance.defaults.headers.common["Auth-Token"];
  }
};

const setHeaderLocaleForAxiosInstance = (locale: string) => {
  axiosInstance.defaults.headers.common["Member-Locale"] = locale;
};

export { setAuthToken, axiosInstance, setHeaderLocaleForAxiosInstance };
