<script setup lang="ts">
import OptionList from "@/components/ui/OptionList.vue";
import type { QuestionOptionCheckbox } from "@/types/uiTypes";

const props = defineProps<{
  allValues: QuestionOptionCheckbox[];
  selectedValues: number[];
  max?: number;
}>();

const emit = defineEmits<{ (e: "change", values: number[]): void }>();

const onChange = (selectedId: number) => {
  if (props.selectedValues.includes(selectedId)) {
    // deselect an answer
    emit(
      "change",
      props.selectedValues.filter((id) => id !== selectedId)
    );
  } else {
    // select only the negative anwser
    const selectedOption = props.allValues.find((opt) => opt.id === selectedId);
    if (selectedOption && selectedOption.negative) return emit("change", [selectedId]);
    // select one more anwser
    emit("change", [...props.selectedValues, selectedId]);
  }
};
</script>

<template>
  <OptionList :allValues="props.allValues" :selectedValues="props.selectedValues" @change="onChange" :max="props.max">
    <template v-slot:filledIcon>
      <img src="@/assets/icons/checkbox-filled.webp" alt="checkbox-filled" />
    </template>
    <template v-slot:emptyIcon>
      <img src="@/assets/icons/checkbox-empty.webp" alt="checkbox-empty" />
    </template>
  </OptionList>
</template>

<style scoped lang="pcss"></style>
