<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits<{ (e: "onClick"): void }>();

const onClick = () => {
  emit("onClick");
};
</script>

<template>
  <div class="back" @click="onClick">
    <img src="@/assets/icons/arrow-grey.svg" alt="arrow" />
    <span class="button-text">{{ t("BUTTON.BACK") }}</span>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/layout.css";

.back {
  font-size: 1.15rem;
  font-weight: 500;
  position: absolute;
  top: 2em;
  left: 1.5em;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  width: 0.5em;
  height: 0.5em;
  color: var(--clr-grey-4);
  & img {
    padding: 0.5em;
    width: inherit;
    height: inherit;
    transform: rotate(180deg);
  }
}
@media (--phone-width) {
  .back {
    font-size: 0.9rem;
    left: 1em;
  }
}
@media (--small-phone-width) {
  .back {
    top: 1.3em;
  }
}
</style>
