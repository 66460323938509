import type { CountryCode } from "@/utils/constants";
import { usePaymentStore } from "@/stores/payment";
import camelcaseKeys from "camelcase-keys";

declare const google: any;

export const GoogleAutocompleteService = {
  instance: null as any,
  country: "" as CountryCode,

  instantiate(country: CountryCode) {
    this.instance = new google.maps.places.AutocompleteService();
    this.country = country;
  },

  setCountry(country: CountryCode) {
    this.country = country;
  },

  async getPlacePredictions(userInput: string) {
    if (!this.instance) return;

    try {
      const request = { input: userInput, componentRestrictions: { country: [this.country] } };
      const res = await this.instance.getPlacePredictions(request);
      const cameledRes = camelcaseKeys(res.predictions, { deep: true });
      return [null, cameledRes];
    } catch (err) {
      console.error("Error fetching Google Autocomplete Predictions", err);
      return [err, null];
    }
  },
};

export const GooglePlacesService = {
  instance: null as any,
  country: "" as CountryCode,

  setCountry(country: CountryCode) {
    this.country = country;
  },

  instantiate(el: HTMLElement) {
    this.instance = new google.maps.places.PlacesService(el);
  },

  async getPlace(placeId: string) {
    return new Promise((resolve) => {
      try {
        if (!this.instance) throw Error("No Instance");

        const request = { fields: ["address_components", "geometry", "formatted_address"], placeId };
        this.instance.getDetails(request, (res: any) => {
          const cameledRes = camelcaseKeys(res, { deep: true });
          return resolve([null, cameledRes]);
        });
      } catch (err) {
        console.error("Error fetching Google Place", err);
        return resolve([true, null]);
      }
    });
  },
};

export const initializeGoogleServices = () => {
  const paymentStore = usePaymentStore();
  if (!paymentStore.selectedCountryCode.value) return;
  GoogleAutocompleteService.instantiate(paymentStore.selectedCountryCode.value! as CountryCode);
  GooglePlacesService.setCountry(paymentStore.selectedCountryCode.value! as CountryCode);
};

export const updateGoogleServiceCountry = (val: CountryCode) => {
  GoogleAutocompleteService.setCountry(val);
  GooglePlacesService.setCountry(val);
};
