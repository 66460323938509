import {
  CountryCode,
  LOCALE,
  Store,
  type CheckoutVersion,
  FormulaPresentation,
  OrderRecipeABVersion,
} from "@/utils/constants";
import { defineStore } from "pinia";
import { getAppLocale } from "@/utils/language";
import { i18n } from "@/includes/i18n-setup";
import { usePaymentStore } from "./payment";
import { useDiscountStore } from "./discount";
import { useSupplementsStore } from "./supplements";
import { useQuestionsStore } from "./questions";
import { StripeService } from "@/services/Stripe";
import { useRoute } from "vue-router";
import { BasicQuestionKey, type AnswerText } from "@/types/questions";

declare module "pinia" {
  export interface PiniaCustomProperties {
    router: any;
  }
}

export const useMainStore = defineStore({
  id: "main",
  state: () => ({
    appLoading: false,
    store: Store.USA as Store,
    ipLocCountry: {
      ip: "" as string,
      code: CountryCode.US as string,
      name: "" as string,
      currency: "" as string,
    },
    countryCodesAll: [] as string[],
    checkoutVersion: "" as CheckoutVersion,
    formulaPresentation: FormulaPresentation.Control,
    hubspotContactEmail: "",
    orderRecipeABVersion: OrderRecipeABVersion.Control,
  }),
  getters: {
    locale(): LOCALE {
      return getAppLocale((i18n.global.locale as any).value);
    },
    isLocationUSA(): boolean {
      return this.ipLocCountry.code === CountryCode.US;
    },
    ipLocationCountryCode(): string {
      return this.ipLocCountry.code;
    },
    localCurrency(): string {
      return this.ipLocCountry.currency;
    },
    isAbandonedRecipeLink(): boolean {
      const route = useRoute();
      return !!route.query.contactId;
    },
    questionnaireWasMadeInUSAFormat(): boolean {
      // * I am using this apparently silly check (instead of "mainStore.isLocationUSA") because there is an edge case where a customer might initially made the questionnaire in the US and saved to Hubspot that questionnaire.
      // * Sometimes in the future he uses Abandoned link outside of US and that triggers HS to update IP. This works as a sort of "Initial IP Country Questionnaire". It's needed because in this case where customer
      // * doesn't have a formulaUuid from initial time he made questionnaire, he will get sent to Loading page where the /from_answers API will trigger and it will fail because of invalid formats. Super edge case 🙄
      const questionsStore = useQuestionsStore();
      return (questionsStore.basicStore.answers[BasicQuestionKey.HEIGHT] as AnswerText).value.includes("'");
    },
  },
  actions: {
    setStore(store: Store | null) {
      this.store = store || Store.USA;

      this.resetAddressAndDiscount();
    },
    setCountryCodesAll(countryCodes: string[]) {
      this.countryCodesAll = countryCodes;
    },
    setIPLocationCountry({ code, name, currency, ip }: { code: string; name: string; currency: string; ip: string }) {
      this.ipLocCountry.code = code;
      this.ipLocCountry.name = name;
      this.ipLocCountry.currency = currency;
      this.ipLocCountry.ip = ip;
    },
    setHubspotContactEmail(contactEmail: string) {
      this.hubspotContactEmail = contactEmail;
    },
    resetAddressAndDiscount() {
      const discountStore = useDiscountStore();
      const paymentStore = usePaymentStore();
      discountStore.$reset();
      paymentStore.resetAddress("deliveryAddress");
      paymentStore.resetAddress("invoiceAddress");
    },
    resetAllStores() {
      const paymentStore = usePaymentStore();
      const discountStore = useDiscountStore();
      const supplementsStore = useSupplementsStore();
      const questionsStore = useQuestionsStore();

      discountStore.$reset();
      paymentStore.$reset();
      supplementsStore.$reset();
      questionsStore.$reset();
      questionsStore.basicStore.$reset();
      questionsStore.healthStore.$reset();
      questionsStore.lifestyleStore.$reset();
      this.$reset();
      StripeService.resetData();
      localStorage.clear();
    },
    setCheckoutVersion(version: CheckoutVersion) {
      this.checkoutVersion = version;
    },
    setFormulaPresentation(version: FormulaPresentation) {
      this.formulaPresentation = version;
    },
    setOrderRecipeABVersion(version: OrderRecipeABVersion) {
      this.orderRecipeABVersion = version;
    },
  },
});
