export const MEMBERS_BASE_PATH = `${import.meta.env.VITE_API_HOST}/members/api/v1`;
export const MEMBERS_BASE_PATH_PROD = `${import.meta.env.VITE_API_HOST_PROD}/members/api/v1`;
export const CHECKOUT_BASE_PATH = `${import.meta.env.VITE_API_HOST}/checkout/api/v1`;
export const SYSTEM_BASE_PATH = `${import.meta.env.VITE_API_HOST}/system/api/v1`;
export const PUBLIC_BASE_PATH = `${import.meta.env.VITE_API_HOST}/public/api/v1`;
export const API_BASE_PATH = `${import.meta.env.VITE_NODE_API_HOST || ""}/api`;
export const RAF_BASE_PATH = `${import.meta.env.VITE_RAD_BASE_PATH || ""}`;
export const NODE_API = "http://localhost:8003/api";

export const API = {
  IP_REGISTRY: `https://api.ipregistry.co?key=${import.meta.env.VITE_IP_REGISTRY}`,
  SIGN_IN: `${MEMBERS_BASE_PATH}/sign_in`,
  MEDS: `${MEMBERS_BASE_PATH}/medications`,
  FORMULA_RECOMMENDATIONS: `${MEMBERS_BASE_PATH}/formula_recommendations/from_answers`,
  // AVAILABLE_PRODUCTS: `${CHECKOUT_BASE_PATH}/available_products?country_code={CC}&store_brand=bioniq&product_family=bioniq_go&store_country={STORE}&ab_test_key={AB_KEY}`,
  AVAILABLE_PRODUCTS: `${CHECKOUT_BASE_PATH}/available_products?country_code={CC}&store_brand=bioniq&product_family=bioniq_go&store_country={STORE}&currency={CURRENCY}`,
  CONTACT: `${API_BASE_PATH}/contact`,
  CONTACT_ID: `${API_BASE_PATH}/contact/id`,
  DISCOUNT_CODE: `${SYSTEM_BASE_PATH}/shopify/discount_status?discount_code={CODE}&store_country={STORE}&store_brand=bioniq&product_type_id={PRODUCT_ID}`,
  CLIENT_SESSION: `${CHECKOUT_BASE_PATH}/submit`,
  PAYMENTS: `${CHECKOUT_BASE_PATH}/create_payment`,
  RESUME_PAYMENT: `${CHECKOUT_BASE_PATH}/resume_payment`,
  COUNTRY_CODES: `${CHECKOUT_BASE_PATH}/available_country_codes?store_country={STORE}&store_brand=bioniq`,
  STRIPE_CLIENT_SESSION: `${API_BASE_PATH}/stripe-client-secret`,
  EXCHANGE_CURRENCY: `${API_BASE_PATH}/exchange?base={BASE}&to={TO}`,
  CHECK_RAF_CODE: `${RAF_BASE_PATH}/raf/check-code?referral_code={CODE}&product_type={PRODUCT}`,
  VALIDATE_ADDRESS: `${MEMBERS_BASE_PATH_PROD}/shipments/validate_address`,
  CHECK_ELIGIBILITY: `${MEMBERS_BASE_PATH}/orders/check_eligibility?email={EMAIL}`,
  FAQ: `${PUBLIC_BASE_PATH}/faqs?locale={LOCALE}&product_type=go`,
};
