<script setup lang="ts">
import type { QuestionOption, QuestionOptionCheckbox } from "@/types/uiTypes";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps<{
  allValues: (QuestionOption | QuestionOptionCheckbox)[];
  selectedValues: number[];
  max?: number;
}>();

const emit = defineEmits<{ (e: "change", value: number): void }>();

const handleClick = (value: QuestionOption) => {
  if (isDisabled(value)) return;
  emit("change", value.id);
};

const isSelected = (id: number) => {
  return props.selectedValues.includes(id);
};

const isDisabled = (op: QuestionOption | QuestionOptionCheckbox) => {
  // if a negative option is selected, the other options are disabled
  if (props.allValues.some((opt: any) => opt.negative)) {
    const isNegativeSelected = props.selectedValues.some(
      (id) => (props.allValues.find((opt) => opt.id === id)! as QuestionOptionCheckbox).negative
    );
    if (!(op as QuestionOptionCheckbox).negative && isNegativeSelected) return true;
  }
  // if reached the maximum option is reached, the other options are disabled
  if (!props.max) return false;
  return props.selectedValues.length === props.max && !props.selectedValues.includes(op.id);
};
</script>

<template>
  <div class="option-list">
    <div
      v-for="opt in props.allValues"
      :key="opt.id"
      :class="{ option: true, selected: isSelected(opt.id), disabled: isDisabled(opt) }"
      @click="handleClick(opt)"
      :data-value="opt.value"
    >
      <div class="icon">
        <slot v-if="isSelected(opt.id)" name="filledIcon"></slot>
        <slot v-else name="emptyIcon"></slot>
      </div>
      <div class="text-value">{{ t(`OPTION.${opt.phraseKey}`) }}</div>
    </div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/layout.css";
@import "@/assets/styles/ui.css";

@mixin defaultOption;

.option-list {
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: baseline;
  & .option {
    border-radius: 0.25em;
    width: 100%;
    max-width: 20em;
    padding: 1em 2em;
    display: flex;
    margin: 0 1.6em 2em 0;

    & .icon {
      flex-shrink: 0;
      width: 1em;
      height: 1em;
      margin: -0.2em 0.5em 0 0;

      & :slotted(img) {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@mixin scrollbar-one .option-list;

@media (--phone-width) {
  .option-list {
    min-height: 100%;
    & .option {
      margin: 0 0 0.5em 0;
      max-width: none;
    }
  }
}
</style>
