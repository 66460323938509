<script setup lang="ts">
import QuestionTextInput from "@/components/question-types/QuestionTextInput.vue";
import type { QuestionKey } from "@/types/questions";
import { USA_Height } from "@/types/questions";
import { computed } from "vue";

const props = defineProps<{
  propKey: QuestionKey;
  value: string;
  errors: {
    feet: boolean;
    inches: boolean;
  };
}>();

const feetValue = computed<string>(() => {
  return props.value.split(`'`)[0] || "";
});

const inchesValue = computed<string>(() => {
  return props.value.split(`'`)[1] || "";
});

const emit = defineEmits<{
  (e: "change", value: { val: string; type: USA_Height }): void;
}>();

const emitChange = (val: string, type: USA_Height) => {
  emit("change", { val, type });
};
</script>
<template>
  <div class="question-height-usa-container">
    <QuestionTextInput
      :key="props.propKey"
      :value="feetValue"
      :errored="props.errors.feet"
      :usaLabel="'FEET'"
      @change="emitChange($event, USA_Height.FEET)"
    />
    <QuestionTextInput
      :key="props.propKey"
      :value="inchesValue"
      :errored="props.errors.inches"
      :usaLabel="'INCHES'"
      @change="emitChange($event, USA_Height.INCHES)"
    />
  </div>
</template>

<style scoped lang="pcss">
.question-height-usa-container {
  width: 100%;
  gap: 1em;
  display: flex;
  justify-content: center;
}
</style>
