import { defineStore } from "pinia";
import { useMainStore } from "@/stores/main";
import { usePaymentProducts } from "@/composables/useCartTexts";
import { CampaignActiveInjectionKey } from "@/utils/constants";
import { computed, inject } from "vue";
import { isCampaignActive, ExcludedCountriesFromNewCampaign } from "@/utils/campaign-trackers";

export const useCampaigns = defineStore("campaignsStore", () => {
  const campaignTracker = inject(CampaignActiveInjectionKey)!;

  const { formatPriceCurrency } = usePaymentProducts();

  const newCampaign = campaignTracker.newCampaign;
  const oldCampaign = campaignTracker.oldCampaign;

  const isProductLightCampaignActive = computed<boolean>(() => {
    return (
      isCampaignActive && (newCampaign.campaignFor.go_single_purchase || oldCampaign.campaignFor.go_single_purchase)
    );
  });

  const isSubscriptionCampaignActive = computed<boolean>(() => {
    return isCampaignActive && (newCampaign.campaignFor.go_subscription || oldCampaign.campaignFor.go_subscription);
  });

  const isExcludedCountry = computed<boolean>(() => {
    return ExcludedCountriesFromNewCampaign.includes(useMainStore().ipLocationCountryCode);
  });

  const activeCampaign = computed(() => {
    return isExcludedCountry.value ? oldCampaign : newCampaign;
  });

  const value = computed<string>(() => {
    return newCampaign.type === "percentage"
      ? `${activeCampaign.value.value}%`
      : formatPriceCurrency(activeCampaign.value.value);
  });

  const code = computed<string>(() => {
    return activeCampaign.value.code;
  });

  const bannerBgColor = computed<string>(() => {
    return activeCampaign.value.bgColor;
  });

  const bannerTextColor = computed<string>(() => {
    return activeCampaign.value.textColor;
  });

  return {
    isProductLightCampaignActive,
    isSubscriptionCampaignActive,
    activeCampaign,
    value,
    code,
    bannerBgColor,
    bannerTextColor,
  };
});
