import type { ActivityValue, AllergicValue, DietValue, MedicalConditionValue, SmokingValue } from "@/stores/lifestyle";
import type { CurrentFitnessGoalValue } from "@/stores/health";
import { useQuestionsStore } from "@/stores/questions";
import type { Medication } from "@/types/apiTypes";
import { BasicQuestionKey, HealthQuestionKey, LifestyleQuestionKey, type AnswerNumberList } from "@/types/questions";
import moment from "moment";
import { allergiesOptions, HealthConcernsOptions, ExternalID } from "./constants";
import { findValueByIdInOptionsList } from "./functions";

export const getAnswersForRecommendation = () => {
  const qStore = useQuestionsStore();
  const answers = qStore.allAnswers;

  const serializedAnswers: ExternalID[] = [
    ExternalID.ALGO_V2,
    keyForAge(qStore.getAnswerInDefaultSystem(BasicQuestionKey.DOB)),
    keyForHeight(qStore.getAnswerInDefaultSystem(BasicQuestionKey.HEIGHT)),
    keyForWeight(qStore.getAnswerInDefaultSystem(BasicQuestionKey.WEIGHT)),
    keyForBMI(
      qStore.getAnswerInDefaultSystem(BasicQuestionKey.WEIGHT),
      qStore.getAnswerInDefaultSystem(BasicQuestionKey.HEIGHT)
    ),
    keyForGender(qStore.healthStore.gender),
    keyForPregnantOrPlanning(qStore.basicStore.isPregnantOrPlanning),
    ...keysForLookingPregnantNursing(qStore.basicStore.lookingPregnantNursing),
    ...keysForHealthConcerns((answers[HealthQuestionKey.CONCERNS] as AnswerNumberList).value),
    keyForActivityLevel(qStore.lifestyleStore.activityAnswer),
    ...keysForDiet(qStore.lifestyleStore.dietAnswer),
    ...keysForAllergies(qStore.lifestyleStore.allergiesAnswer),
    ...keyForMedicalCondition(qStore.lifestyleStore.medicalConditionAnswer),
    ...keysForMedication(answers[LifestyleQuestionKey.MEDS].value as Medication[]),
    keyForCommonColds(qStore.healthStore.commonColdsAnswer),
    ...keysForCurrentFitnessGoal(qStore.healthStore.currentFitnessGoalsWithAnswer),
    keyForSmoking(qStore.lifestyleStore.smokingAnswer),
    keyForBloodPressure(qStore.healthStore.bloodPressureAnswer),
  ];
  return serializedAnswers;
};

const keyForBloodPressure = (val: boolean) => {
  return val ? ExternalID.LOW_BLOOD_PRESSURE_YES : ExternalID.LOW_BLOOD_PRESSURE_NO;
};

const keyForSmoking = (answer: SmokingValue): ExternalID => {
  return answer === "yes" ? ExternalID.SMOKE_YES : ExternalID.SMOKE_NO;
};

const keysForCurrentFitnessGoal = (answer: CurrentFitnessGoalValue): ExternalID[] => {
  const options = [ExternalID.LOSE_WEIGHT_NO, ExternalID.MUSCLE_MASS_NO, ExternalID.STAMINA_NO];
  if (answer === null) return options;

  if (answer === "build-muscle") {
    options.push(ExternalID.MUSCLE_MASS_YES);
    options.splice(options.findIndex((opt) => opt === ExternalID.MUSCLE_MASS_NO)!, 1);
  }
  if (answer === "lose-weight") {
    options.push(ExternalID.LOSE_WEIGHT_YES);
    options.splice(options.findIndex((opt) => opt === ExternalID.LOSE_WEIGHT_NO)!, 1);
  }
  if (answer === "improve-stamina") {
    options.push(ExternalID.STAMINA_YES);
    options.splice(options.findIndex((opt) => opt === ExternalID.STAMINA_NO)!, 1);
  }
  return options;
};

const keyForCommonColds = (val: boolean): ExternalID => {
  return val ? ExternalID.COLDS_YES : ExternalID.COLDS_NO;
};

const keyForAge = (dob: string): ExternalID => {
  const age = moment().diff(moment(dob, "YYYY/MM/DD"), "years");
  switch (true) {
    case age <= 17:
      return ExternalID.AGE_LE_17;
    case age <= 40:
      return ExternalID.AGE_LE_40;
    case age <= 60:
      return ExternalID.AGE_LE_60;
    default:
      return ExternalID.AGE_ABOVE_60;
  }
};

const keyForHeight = (heightS: string): ExternalID => {
  const height = parseInt(heightS);

  switch (true) {
    case height < 150:
      return ExternalID.HEIGHT_L_150;
    case height <= 180:
      return ExternalID.HEIGHT_LE_180;
    default:
      return ExternalID.HEIGHT_ABOVE_180;
  }
};

const keyForWeight = (weightS: string): ExternalID => {
  const weight = parseInt(weightS);

  switch (true) {
    case weight < 50:
      return ExternalID.WEIGHT_L_50;
    case weight < 75:
      return ExternalID.WEIGHT_L_75;
    case weight <= 90:
      return ExternalID.WEIGHT_LE_90;
    default:
      return ExternalID.WEIGHT_ABOVE_90;
  }
};

const keyForBMI = (weight: string, height: string) => {
  const bmi = parseInt(weight) / Math.pow(parseInt(height) / 100, 2);
  switch (true) {
    case bmi < 18.5:
      return ExternalID.BMI_L_18;
    case bmi <= 25:
      return ExternalID.BMI_LE_25;
    case bmi <= 30:
      return ExternalID.BMI_LE_30;
    case bmi <= 35:
      return ExternalID.BMI_LE_35;
    case bmi <= 40:
      return ExternalID.BMI_LE_40;
    default:
      return ExternalID.BMI_ABOVE_40;
  }
};

const keyForGender = (gender: "female" | "male" | "none"): ExternalID => {
  switch (gender) {
    case "female":
      return ExternalID.FEMALE;
    case "male":
      return ExternalID.MALE;
    default:
      return ExternalID.NOT_SPECIFIC_GENDER;
  }
};

const keyForPregnantOrPlanning = (isPregOrPlanning: boolean): ExternalID => {
  return isPregOrPlanning ? ExternalID.PREGNANCY : ExternalID.NO_PREGNANCY_PLANS;
};

const keysForLookingPregnantNursing = (answerValues: ("looking" | "pregnant" | "nursing")[]): ExternalID[] => {
  const externalIds = [];
  if (answerValues.includes("looking")) externalIds.push(ExternalID.PREGNANCY_PLAN);
  if (answerValues.includes("pregnant")) externalIds.push(ExternalID.PREGNANCY_IN_PROGRESS);
  if (answerValues.includes("nursing")) externalIds.push(ExternalID.NURSING);
  return externalIds;
};

const keysForHealthConcerns = (answerIds: number[]): ExternalID[] => {
  const answerValues = answerIds.map((id) => findValueByIdInOptionsList(HealthConcernsOptions, id));
  const externalIds = [];
  externalIds.push(answerValues.includes("skin") ? ExternalID.SKIN_YES : ExternalID.SKIN_NO);
  externalIds.push(answerValues.includes("libido") ? ExternalID.LIBIDO_YES : ExternalID.LIBIDO_NO);
  externalIds.push(answerValues.includes("memory") ? ExternalID.MEMORY_YES : ExternalID.MEMORY_NO);
  externalIds.push(answerValues.includes("liver") ? ExternalID.LIVER_YES : ExternalID.LIVER_NO);
  externalIds.push(answerValues.includes("sleep") ? ExternalID.SLEEP_YES : ExternalID.SLEEP_NO);
  externalIds.push(answerValues.includes("aging") ? ExternalID.AGING_YES : ExternalID.AGING_NO);
  externalIds.push(answerValues.includes("heart") ? ExternalID.HEART_YES : ExternalID.HEART_NO);
  externalIds.push(answerValues.includes("muscle") ? ExternalID.MUSCLE_YES : ExternalID.MUSCLE_NO);
  externalIds.push(answerValues.includes("immune-support") ? ExternalID.IMMUNE_YES : ExternalID.IMMUNE_NO);
  if (answerValues.includes("iron")) externalIds.push(ExternalID.IRON_YES);
  externalIds.push(answerValues.includes("bones") ? ExternalID.BONES_YES : ExternalID.BONES_NO);
  return externalIds;
};

const keyForActivityLevel = (answer: ActivityValue): ExternalID => {
  if (answer === "sedentary") return ExternalID.SEDENTARY;
  if (answer === "lightly-active") return ExternalID.LIGHTLY_ACTIVE;
  if (answer === "moderately-active") return ExternalID.MODERATELY_ACTIVE;
  return ExternalID.VERY_ACTIVE;
};

const keysForDiet = (answers: DietValue): ExternalID[] => {
  const externalIds = [];
  externalIds.push(answers.includes("vegetarian") ? ExternalID.VEGETARIAN_YES : ExternalID.VEGETARIAN_NO);
  externalIds.push(answers.includes("vegan") ? ExternalID.VEGAN_YES : ExternalID.VEGAN_NO);
  return externalIds;
};

const keysForAllergies = (answers: AllergicValue[]): ExternalID[] => {
  const externalIds = [];
  externalIds.push(answers.includes("allergic-b") ? ExternalID.ALLERGIC_B_YES : ExternalID.ALLERGIC_B_NO);
  externalIds.push(answers.includes("allergic-c") ? ExternalID.ALLERGIC_C_YES : ExternalID.ALLERGIC_C_NO);
  externalIds.push(answers.includes("allergic-d") ? ExternalID.ALLERGIC_D_YES : ExternalID.ALLERGIC_D_NO);
  externalIds.push(
    answers.includes("allergic-rhodiola") ? ExternalID.ALLERGIC_RHODIOLA_YES : ExternalID.ALLERGIC_RHODIOLA_NO
  );
  externalIds.push(
    answers.includes("allergic-coenzyme-q10")
      ? ExternalID.ALLERGIC_COENZYME_Q10_YES
      : ExternalID.ALLERGIC_COENZYME_Q10_NO
  );
  externalIds.push(answers.includes("allergic-iron") ? ExternalID.ALLERGIC_IRON_YES : ExternalID.ALLERGIC_IRON_NO);
  if (!answers.includes("allergic-iodine")) externalIds.push(ExternalID.ALLERGIC_IODINE_NO);
  if (answers.includes("none-above")) externalIds.push(ExternalID.NO_ALLERGIES);
  return externalIds;
};

const keyForMedicalCondition = (answers: MedicalConditionValue[]): ExternalID[] => {
  const externalIds = [];
  if (answers.includes("cholesterol")) externalIds.push(ExternalID.COLESTEROL);
  if (!answers.includes("hyperthyroidism")) externalIds.push(ExternalID.HYPERTIROIDISM_NO);
  answers.includes("hypertension")
    ? externalIds.push(ExternalID.HYPERTENSION_YES)
    : externalIds.push(ExternalID.HYPERTENSION_NO);
  return externalIds;
};

const getMedGroups = (meds: Medication[]) => {
  return [...new Set(meds.map((med) => med.pharmacologicalGroup))];
};

const keysForMedication = (meds: Medication[]): ExternalID[] => {
  const externalIds = [];
  const medGroups = getMedGroups(meds);
  if (!medGroups.includes("diuretic")) externalIds.push(ExternalID.DIURETICS_NO);
  if (!medGroups.includes("statins")) externalIds.push(ExternalID.STATINS_NO);
  if (!medGroups.includes("blood-thinner")) externalIds.push(ExternalID.BLOOD_THINNERS_NO);
  return externalIds;
};

export const getAnswersForOrderSubmit = () => {
  const qStore = useQuestionsStore();
  const answers = qStore.allAnswers;

  const maternityStat = qStore.basicStore.lookingPregnantNursing;

  const questionAnswerKeys: { [key: string]: ExternalID[] | ExternalID } = {
    "algo-maternity": keyForPregnantOrPlanning(qStore.basicStore.isPregnantOrPlanning),
    "algo-maternity-status": maternityStat.length ? keysForLookingPregnantNursing(maternityStat) : ExternalID.NONE,
    "algo-goals": concernsKeysForOrderSubmit((answers[HealthQuestionKey.CONCERNS] as AnswerNumberList).value),
    "algo-diet": dietKeyForOrderSubmit(qStore.lifestyleStore.dietAnswer),
    "algo-allergies": [...allergyKeysForOrderSubmit(qStore.lifestyleStore.allergiesAnswer)],
    "algo-medications": medsForOrderSubmit(answers[LifestyleQuestionKey.MEDS].value as Medication[]),
    "algo-medical-condition": [
      ...medicalContForOrderSubmit(qStore.lifestyleStore.medicalConditionAnswer),
      keyForBloodPressure(qStore.healthStore.bloodPressureAnswer),
    ],
    "algo-activity-level": keyForActivityLevel(qStore.lifestyleStore.activityAnswer),
    "algo-intake-form": ExternalID.GRANULES,
    "algo-fitness-aim": keysForCurrentFitnessGoal(qStore.healthStore.currentFitnessGoalsWithAnswer),
    "algo-smoking": keyForSmoking(qStore.lifestyleStore.smokingAnswer),
    "algo-colds": keyForCommonColds(qStore.healthStore.commonColdsAnswer),
  };

  const serializedAnswers = Object.keys(questionAnswerKeys).map((key) => {
    return {
      questionKey: key,
      answerKeys: typeof questionAnswerKeys[key] === "string" ? [questionAnswerKeys[key]] : questionAnswerKeys[key],
    };
  });
  return serializedAnswers;
};

const concernsKeysForOrderSubmit = (answerIds: number[]): ExternalID[] => {
  return answerIds.map((id) => HealthConcernsOptions.find((option) => option.id === id)!.apiKey!);
};

const dietKeyForOrderSubmit = (answer: DietValue): ExternalID => {
  switch (answer) {
    case "vegetarian":
      return ExternalID.VEGETARIAN_YES;
    case "vegan":
      return ExternalID.VEGAN_YES;
    case "pescetarianism":
      return ExternalID.PESCETARIANISM;
    default:
      return ExternalID.NO_DIET;
  }
};

const allergyKeysForOrderSubmit = (answerKeys: AllergicValue[]): ExternalID[] => {
  return answerKeys.map((key) => allergiesOptions.find((option) => option.value === key)!.apiKey!);
};

const medicalContForOrderSubmit = (answers: MedicalConditionValue[]): ExternalID[] => {
  const externalIds = [];
  if (answers.includes("cholesterol")) externalIds.push(ExternalID.COLESTEROL);
  if (answers.includes("hyperthyroidism")) externalIds.push(ExternalID.HYPERTIROIDISM_YES);
  if (answers.includes("hypertension")) externalIds.push(ExternalID.HYPERTENSION_YES);
  if (answers.includes("none-above")) externalIds.push(ExternalID.NO_MEDICAL_COND);
  return externalIds;
};

const medsForOrderSubmit = (meds: Medication[]): ExternalID[] => {
  const externalIds = [];
  if (!meds.length) return [ExternalID.MEDS_NO];
  const medGroups = getMedGroups(meds);
  if (medGroups.includes("diuretic")) externalIds.push(ExternalID.MEDS_DIURETICS);
  if (medGroups.includes("statins")) externalIds.push(ExternalID.MEDS_STATINS);
  if (medGroups.includes("blood-thinner")) externalIds.push(ExternalID.MEDS_THINNERS);
  return externalIds;
};
