import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/router/routes";
import { PaymentKey } from "@/types/questions";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (Object.values(PaymentKey).includes(to.name as any)) return { top: 0, behavior: "smooth" };
  },
});

const hasQueryParams = (route: any) => {
  return !!Object.keys(route.query).length;
};

router.beforeEach((to, from, next: any) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }
});

export default router;
