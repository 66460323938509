import { defineStore, storeToRefs } from "pinia";
import { isCampaignActive } from "@/utils/campaign-trackers";
import { addQueryParamToUrl } from "@/utils/functions";
import { useCampaigns } from "@/stores/campaigns";
import { usePaymentStore } from "@/stores/payment";

export const useDiscountsStore = defineStore("discountsStore", () => {
  const paymentStore = usePaymentStore();

  const getAffiliateDiscount = (): string => {
    if (typeof window.Inflektion === "object" && typeof window.Inflektion.getCouponCode === "function") {
      const discountCode = window.Inflektion.getCouponCode();
      return discountCode || "";
    }
    return "";
  };

  const addDiscountToUrl = (currentUrl: string) => {
    // PRIORITY
    // 1 Affiliates, 2 URL Discounts, 3 Active Campaign
    const newUrl = new URL(currentUrl);

    const affiliateDiscount = getAffiliateDiscount();
    const urlDiscount = newUrl.searchParams.get("discount") || "";

    const campaigns = useCampaigns();
    const { activeCampaign, code } = storeToRefs(campaigns);
    const campaignDiscount =
      (isCampaignActive && activeCampaign.value.campaignFor[paymentStore.selectedProductCategory] && code.value) || "";

    newUrl.searchParams.delete("discount");

    const config = { key: "discount", value: "" };
    return addQueryParamToUrl(currentUrl, { ...config, value: affiliateDiscount || urlDiscount || campaignDiscount });
  };

  return {
    addDiscountToUrl,
  };
});
