import { computed, type Ref, unref } from "vue";
import { questionToPhraseStep } from "@/utils/constants";
import type { SubSectionKey, RouteKey, SectionKey, OtherRouteKey, PaymentKey } from "@/types/questions";
import { useRoute } from "vue-router";
import { type CountryCode, countryCodeToPhrase } from "@/utils/constants";
import { useMainStore } from "@/stores/main";
import { useI18n } from "vue-i18n";

export function usePhraseTranslationShortcuts(subSection?: Ref<SubSectionKey> | SubSectionKey) {
  const { t } = useI18n();
  const route = useRoute();

  const phraseNrByRouteName = computed<string>(() => {
    return questionToPhraseStep[route.name as Exclude<RouteKey, SectionKey | OtherRouteKey | PaymentKey>];
  });

  const phraseBySubSection = computed<string>(() => {
    return questionToPhraseStep[unref(subSection!)];
  });

  const ipCountryName = (cc: string) => {
    const countryName = countryCodeToPhrase?.[cc as CountryCode];
    if (countryName) return t(countryName);
    return useMainStore().ipLocCountry.name;
  };

  return { phraseNrByRouteName, phraseBySubSection, ipCountryName };
}
