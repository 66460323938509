<script setup lang="ts">
import OptionList from "@/components/ui/OptionList.vue";
import type { QuestionOption } from "@/types/uiTypes";
import { computed } from "vue";

const props = defineProps<{
  allValues: QuestionOption[];
  selectedValue: number | null;
}>();

const selectedValues = computed<number[]>(() => (props.selectedValue ? [props.selectedValue] : []));

const emit = defineEmits<{ (e: "change", value: number): void }>();

const onChange = (value: number) => {
  emit("change", value);
};
</script>

<template>
  <OptionList
    :allValues="props.allValues"
    :selectedValues="selectedValues"
    @change="onChange"
    class="radio-button-list"
  >
    <template v-slot:filledIcon>
      <img src="@/assets/icons/radio-btn-on.webp" alt="checkbox-filled" />
    </template>
    <template v-slot:emptyIcon>
      <img src="@/assets/icons/radio-btn-off.webp" alt="checkbox-empty" />
    </template>
  </OptionList>
</template>

<style lang="pcss">
.option-list.radio-button-list {
  & .option {
    border-radius: 2.5em;
    & .icon {
      margin-right: 1.2em;
    }
  }
}
</style>
