import { useDiscountStore } from "@/stores/discount";
import { useMainStore } from "@/stores/main";
import { CampaignTracker, isCampaignActive } from "@/utils/campaign-trackers";

export const setDiscount = (to: any, _from: any, next: any) => {
  const discountStore = useDiscountStore();
  const mainStore = useMainStore();

  const discountCode = to.query.discount;
  if (!discountCode) return next();

  discountStore.validate(discountCode, mainStore.store);
  next();
};

export const setReferral = (to: any, _from: any, next: any) => {
  const discountStore = useDiscountStore();
  const referralCodeUrl = to.query.referred_by;
  const referralCodeAlreadyInState = discountStore.referral.code;
  if (!referralCodeUrl && !referralCodeAlreadyInState) return next();

  if (referralCodeUrl) discountStore.validateReferral(referralCodeUrl);
  else if (referralCodeAlreadyInState) discountStore.validateReferral(referralCodeAlreadyInState);
  next();
};

export const setCampaignDiscount = () => {
  if (!isCampaignActive) return;

  // TODO remove after W33 Campaign; don't preapply the discount for this campaign
  if (CampaignTracker.newCampaign.code === "welcome33") return;

  const discountStore = useDiscountStore();
  discountStore.validate(CampaignTracker.newCampaign.code);
};

export const resetDiscount = () => {
  const discountStore = useDiscountStore();
  discountStore.resetDiscount();
};

export const resetReferral = () => {
  const discountStore = useDiscountStore();
  discountStore.resetReferral();
};
