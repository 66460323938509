<script setup lang="ts">
import { useLegal } from "@/composables/useLegal";

const { linkPrivacy } = useLegal();

defineProps<{
  consentSelected: boolean;
  phraseKey: string;
  errored?: boolean;
}>();

const emit = defineEmits<{ (e: "toggleConsent"): void }>();
</script>

<template>
  <div class="accept-toggle" data-toggle="privacy">
    <div class="icon" @click="emit('toggleConsent')">
      <img v-if="errored" src="@/assets/icons/checkbox-empty-red.svg" alt="checkbox-empty" data-img="error" />
      <img v-else-if="consentSelected" src="@/assets/icons/checkbox-filled.webp" alt="checkbox-filled" />
      <img v-else src="@/assets/icons/checkbox-empty.webp" alt="checkbox-empty" />
    </div>
    <div :class="{ text: true, errored: errored }">
      <i18n-t :keypath="phraseKey" tag="div">
        <a :href="linkPrivacy" target="_blank">{{ $t("PAY.LEGAL.MARKETING.PRIVACY") }}</a>
      </i18n-t>
    </div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/ui.css";

@mixin privacyConsent;
</style>
