<script setup lang="ts">
import { useI18n } from "vue-i18n";
import BackButton from "@/components/ui/BackButton.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import LegalLinksComp from "@/components/ui/LegalLinksComp.vue";

const { t } = useI18n();

const props = defineProps<{
  valid: boolean;
  showNoBtn: boolean;
  phraseKeys: { qKey: string; ssKey: string };
}>();

const emit = defineEmits<{
  (e: "navigate", to: "BACK" | "FORWARD" | "FORWARD_EMPTY"): void;
}>();
</script>

<template>
  <div class="question-screen-container">
    <div class="header">
      <BackButton @onClick="emit('navigate', 'BACK')"></BackButton>
      <div class="bioniq-logo">
        <img src="@/assets/icons/logo.svg" alt="bioniq-logo" />
      </div>
      <ProgressBar />
    </div>
    <div class="content">
      <div class="question-container">
        <div class="question-section">{{ $t(`QUESTION_SUBSECTION.${phraseKeys.ssKey}`) }}</div>
        <div class="question-text">{{ $t(`QUESTION_${phraseKeys.qKey}.TITLE`) }}</div>
        <slot name="hint"></slot>
      </div>
      <div :class="{ 'question-comp': true, double: showNoBtn }" @keyup.enter="emit('navigate', 'FORWARD')">
        <slot name="questComp"></slot>
        <div class="comp-bottom">
          <slot name="why-modal"></slot>
          <LegalLinksComp />
        </div>
      </div>
      <div :class="{ 'question-footer': true, double: showNoBtn }">
        <div v-if="showNoBtn" class="btn-inv-cont">
          <div :class="{ button: true, disabled: false, secondary: true }" @click="emit('navigate', 'FORWARD_EMPTY')">
            <div class="button-text">{{ t("BUTTON.NO_IM_NOT") }}</div>
          </div>
        </div>

        <div class="btn-cont">
          <div :class="{ button: true, disabled: !props.valid }" @click="emit('navigate', 'FORWARD')">
            <div class="button-text">{{ t("BUTTON.CONTINUE") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/layout.css";
@import "@/assets/styles/ui.css";

@mixin secondaryButton;

@mixin header;

.question-screen-container {
  height: 100%;
  background: var(--background-color);
  display: flex;
  flex-direction: column;
}

.progress {
  margin: 0 auto;
  width: fit-content;
}

.content {
  display: grid;
  grid-template-rows: minmax(min-content, var(--content-quest-height)) auto max-content;
  width: 70%;
  height: 100%;
  margin: 0 auto;

  @media (--large-laptop-width) {
    width: 80%;
    grid-template-rows: minmax(min-content, var(--content-quest-height)) auto max-content;
  }
  @media (--laptop-width) {
    width: 90%;
    grid-template-rows: minmax(min-content, var(--content-quest-height-laptop)) auto max-content;
  }
  @media (--tablet-portrait) {
    grid-template-rows: minmax(min-content, var(--content-quest-height)) auto max-content;
  }
  @media (--phone-width) and (min-height: 640px) {
    grid-template-rows: minmax(min-content, var(--content-quest-height-phone)) auto max-content;
  }
  /* if keyboard is open */
  @media (--phone-width) and (max-height: 639px) {
    grid-template-rows: minmax(min-content, var(--content-quest-height-phone)) auto max-content;
  }
  @media (--small-phone-width) and (min-height: 640px) {
    grid-template-rows: minmax(min-content, var(--content-quest-height-phone)) auto max-content;
  }
}

.question-container {
  height: auto;
  text-align: center;
  margin-bottom: 0.5em;
  & .question-section {
    font-weight: bold;
    font-size: 1rem;
    color: var(--clr-grey-4);
    letter-spacing: 0.06em;
    margin-bottom: 0.5em;
  }
  & .question-text {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--clr-black-3);
  }

  & :slotted(.question-hint) {
    font-weight: 500;
    font-size: 1rem;
    color: var(--clr-black-3);
  }

}
.question-comp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  & .comp-bottom {
    width: 100%;
  }
  & :slotted(.qc-text-input), & :slotted(.qc-date) {
    max-width: 25em;
    margin: 0 auto;
  }
  & :slotted(.qc-multiple) {
    & .option-list {
      max-width: 55em;
      margin: 0 auto;
      & .option {
        width: unset;
        max-width: unset;
        padding: 1em;
        margin: 0 0.8em 1em 0;
        & .icon {
          display: none;
        }
      }
    }
  }
  & .comp-bottom {
    width: 80%;
  }
}
.question-footer {
  display: grid;
  grid-template-rows: 5em 2em 1em;
  margin: 0 auto ;
  align-items: center;
  justify-items: center;

  & .btn-cont {
    height: 70%;
  }
  & .btn-inv-cont {
    height: 70%;
  }
  & .button {
    height: 100%;
  }
}
.question-footer.double {
  grid-template-rows: var(--continue-container-phone-double-button-height) var(--continue-container-phone-double-button-height) 2em 1em;
}


@media (--phone-height-landscape), (--phone-width) {
  .question-screen-container {
    & .content {
      height: unset;
      min-height: calc(100% - var(--top-bar-height-phone) - 2em);
      & .question-text {
        font-size: 1rem;
      }
      & :slotted(.question-hint) {
        font-size: 0.9rem;
      }
    }
  }

  .question-comp {
    & :slotted(.qc-multiple) {
      & .option-list {
        & .option {
          padding: 0.5em 1em;
          margin: 0 0.3em 0.6em 0;
        }
      }
    }
    & .comp-bottom {
      margin: 0 0 var(--continue-container-phone-height) 0;
      width: 100%;
    }
    & :deep(.terms-and-cond) {
      position: relative;
      width: 100%;
      height: var(--section-legal-links-phone-height);
      padding: 0;
      align-items: center;
    }
  }
  .question-comp.double {
    & .comp-bottom {
      margin: 0 0 calc(var(--continue-container-phone-height) + var(--continue-container-phone-double-button-height)) 0;
    }
  }

  .question-footer {
    box-shadow: 0 -8px 10px rgba(200, 200, 200, 0.75);
    position: fixed;
    display: block;
    box-sizing: border-box;
    height: var(--continue-container-phone-height);
    background-color: white;
    padding: calc(var(--continue-container-phone-height) / 4);
    width: 100%;
    bottom: 0;
    left: 0;
    margin-top: unset;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    & .btn-cont {
      height: 100%;
    }
    & .button {
      height: 100%;
      width: 100%;
    }
  }
  .question-footer.double {
    height: calc(var(--continue-container-phone-height) + var(--continue-container-phone-height) / 2 + var(--continue-container-phone-height) / 4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & .btn-inv-cont {
      height: calc(var(--continue-container-phone-height) / 2);
      width: 100%;
    }
    & .btn-cont {
      height: calc(var(--continue-container-phone-height) / 2);
      width: 100%;
    }
  }
}

@media (--small-phone-width) {
  .question-screen-container {
    & .content {
      min-height: calc(100% - var(--top-bar-height-small-phone) - 2em);
    }
  }
}
</style>
