<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { WhyType } from "@/types/questions";
import { useQuestionsStore } from "@/stores/questions";
import { LifestyleQuestionKey } from "@/types/questions";

const { t } = useI18n();

const props = defineProps<{
  whyType?: WhyType;
  phraseKey: string;
}>();

const questStore = useQuestionsStore();

const footnoteTitle = computed<string>(() => {
  if (questStore.activeQuestionKey === LifestyleQuestionKey.EMAIL) return t("LINK.WHAT_NEXT");
  else return t(`LINK.${props.whyType}`);
});
</script>

<template>
  <div class="why-we-ask-container">
    <div class="row">
      <div class="why-we-ask-icon">
        <img src="@/assets/icons/why-we-ask.svg" alt="why-we-ask" />
      </div>
      <div class="title">{{ footnoteTitle }}</div>
    </div>
    <div class="text">{{ t(`QUESTION_${phraseKey}.WHY`) }}</div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/layout.css";
.why-we-ask-container {
  width: 60%;
  margin: 1em auto 0 auto;
  text-align: center;
  & .row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5em;
    & .why-we-ask-icon {
      margin-right: 1em;
      width: 1.5em;
      height: 1.5em;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    & .title {
      font-weight: bold;
      font-size: 1rem;
    }
  }
  & .text {
    font-size: 0.9rem;
  }
}

@media (--laptop-width) {
  .why-we-ask-container {
    width: 80%;
  }
}

@media (--phone-width) {
  .why-we-ask-container {
    width: 100%;
    & .text {
      font-size: 0.8rem;
    }
  }
}
@media (--phone-height-landscape) {
  .why-we-ask-container {
    width: 100%;
  }
}
@media (--tablet-portrait) {
  .why-we-ask-container {
    width: 90%;
  }
}
</style>
