import { ref, watch } from "vue";

export function useTextInput(value: any, emit: any) {
  const emitUpdate = (event: Event) => {
    const value = (event.target as HTMLInputElement).value;
    emit("update", value);
  };

  const focused = ref(value.value ? true : false);

  watch(
    () => value.value,
    () => {
      if (value.value) focused.value = true;
      else focused.value = false;
    }
  );

  const focusInput = (event: Event) => {
    if (value.value) return;
    if (event.target) {
      focused.value = event.target === document.activeElement;
    } else {
      focused.value = !focused.value;
    }
  };

  return { value, focused, emitUpdate, focusInput };
}
