import { useMainStore } from "@/stores/main";
import { CountryCode, LocalStorageKeys, Store } from "@/utils/constants";
import { getAppLocale, changeLanguage } from "@/utils/language";
import { i18n } from "@/includes/i18n-setup";
import axios from "axios";
import { API } from "@/utils/api/api_paths";
import { usePaymentStore } from "@/stores/payment";
import { setHeaderLocaleForAxiosInstance } from "@/utils/api/axios_instance";
import { useQuestionsStore } from "@/stores/questions";
import { BasicQuestionKey, QuestionType, type Question } from "@/types/questions";
import { useHealthStore } from "@/stores/health";

export const initializeLanguage = async (to: any) => {
  const queryLocale = to.query.lang || localStorage.getItem(LocalStorageKeys.locale);
  const locale = getAppLocale(queryLocale);
  setHeaderLocaleForAxiosInstance(locale);

  if (locale === (i18n.global.locale as any).value) return true;

  await changeLanguage(locale);
  return true;
};

export const setIPLocationCountry = async () => {
  const mainStore = useMainStore();
  const paymentStore = usePaymentStore();
  try {
    const res: any = await axios.get(API.IP_REGISTRY);
    const countryCode: string = res.data.location.country.code;
    const countryName: string = res.data.location.country.name;
    const ip: string = res.data.ip;
    const countryCurrency: string = res.data.currency.code;
    mainStore.setIPLocationCountry({ code: countryCode, name: countryName, currency: countryCurrency, ip });
    paymentStore.setSelectedCountryCode(countryCode);
  } catch (err) {
    console.error("Error fetching IP location data, ", err);
  }
  return true;
};

export const getCountryCodes = async () => {
  const url = API.COUNTRY_CODES.replace("{STORE}", Store.USA);
  try {
    const res: any = await axios.get(url);
    return [null, res.data.country_codes] as [null, CountryCode[]];
  } catch (err) {
    console.error(`Error fetching the country codes for the USA store, `, err);
    return [true, null] as [boolean, null];
  }
};

export const initializeCountryCodes = async () => {
  const [err, res] = await getCountryCodes();
  if (err) return false;

  const mainStore = useMainStore();
  mainStore.setCountryCodesAll(res as CountryCode[]);

  return true;
};

export const adaptQuestionsType = () => {
  const mainStore = useMainStore();
  if (!mainStore.isLocationUSA) return true;

  const questStore = useQuestionsStore();
  const heightQuestion = questStore.basicStore.questions.find((q: Question) => q.key === BasicQuestionKey.HEIGHT);
  heightQuestion!.type = QuestionType.NUMBER_HEIGHT_USA;

  return true;
};

export const setDataFromStorage = () => {
  const qStore = useQuestionsStore();

  qStore.loadAnswersFromStorage();
  qStore.loadQuestionsFromStorage();
  return true;
};

export const setHealthGoalsFromUrl = (route: any) => {
  if (!route.query.hg) return true;
  const concerns = route.query.hg.split(",");
  const healthStore = useHealthStore();
  healthStore.setHealthConcernsFromUrl(concerns);
  return true;
};
