<script setup lang="ts">
import OptionList from "@/components/ui/OptionList.vue";
import type { QuestionOption } from "@/types/uiTypes";

const props = defineProps<{
  allValues: QuestionOption[];
  selectedValues: number[];
  max?: number;
}>();

const emit = defineEmits<{ (e: "change", values: number[]): void }>();

const onChange = (selectedId: number) => {
  if (props.selectedValues.includes(selectedId)) {
    emit(
      "change",
      props.selectedValues.filter((id) => id !== selectedId)
    );
  } else {
    emit("change", [...props.selectedValues, selectedId]);
  }
};
</script>

<template>
  <OptionList :allValues="props.allValues" :selectedValues="props.selectedValues" @change="onChange" :max="props.max">
  </OptionList>
</template>

<style lang="pcss"></style>
