import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { type AvailableProduct, ProductCategory, Currency } from "@/types/apiTypes";
import { usePaymentStore } from "@/stores/payment";
import { useDiscountStore } from "@/stores/discount";
import { CurrencyToSymbol } from "@/utils/constants";

export function usePaymentProducts() {
  const { t } = useI18n();
  const paymentStore = usePaymentStore();
  const discountStore = useDiscountStore();

  const formatPriceCurrency = (price: number | string, currency?: Currency) => {
    if (!paymentStore.selectedProduct?.currency) return "";

    const currencySymbol = CurrencyToSymbol[currency || paymentStore.selectedProduct.currency];

    switch (paymentStore.selectedProduct!.currency) {
      case Currency.EUR:
        return `${price}${currencySymbol}`;
      case Currency.GBP:
        return `${currencySymbol}${price}`;
      case Currency.AED:
        return `${currencySymbol}${price}`;
      case Currency.USD:
        return `${currencySymbol}${price}`;
    }
  };

  const getProductTotalPrice = (product: AvailableProduct): number => {
    return product.price + getProductsDeliveryPrice(product);
  };

  const getProductsDeliveryPrice = (product: AvailableProduct) => {
    return product.shippingZones.length > 0 ? product.shippingZones[0].deliveryPrice : 0;
  };

  const maxPossibleFullPriceCut = computed<string>(() => {
    const singleProduct = paymentStore.singlePurchaseAvailableProduct;
    return formatPriceCurrency(singleProduct.price + getProductsDeliveryPrice(singleProduct), singleProduct.currency);
  });

  const productPriceFormatted = computed<string>(() => {
    if (!paymentStore.selectedProduct!.price) return t("PAY.SP.PRODUCTS.FREE");
    if (paymentStore.selectedProduct!.category === ProductCategory.GO_SINGLE_PURCHASE) {
      return formatPriceCurrency(paymentStore.selectedProduct!.price);
    } else if (paymentStore.selectedProduct!.category === ProductCategory.GO_SUBSCRIPTION) {
      const currencyAndPrice = formatPriceCurrency(paymentStore.selectedProduct!.price);
      return `${currencyAndPrice}/${t("PAY.SP.MONTH")}`;
    }
    return "" as never;
  });

  const productTitle = (product: AvailableProduct) => {
    if (product.category === ProductCategory.GO_SINGLE_PURCHASE) {
      return t("PAY.SP.PRODUCTS.SINGLE", product.quantity);
    } else if (product.category === ProductCategory.GO_SUBSCRIPTION) {
      return t("PAY.SP.PRODUCTS.SUBSCRIPTIONS");
    }
  };

  const deliveryPriceFormatted = computed<string>(() => {
    if (!paymentStore.spShippingZone!.deliveryPrice) return t("PAY.SP.PRODUCTS.FREE"); ////
    return formatPriceCurrency(paymentStore.spShippingZone!.deliveryPrice);
  });

  const deliveryPriceFormatted1 = (product: AvailableProduct) => {
    return formatPriceCurrency(getProductsDeliveryPrice(product), product.currency);
  };

  const totalPriceFormatted = (product: AvailableProduct) => {
    if (product.category === ProductCategory.GO_SINGLE_PURCHASE) {
      return formatPriceCurrency(getProductTotalPrice(product), product.currency);
    } else if (product.category === ProductCategory.GO_SUBSCRIPTION) {
      const currencyAndPrice = formatPriceCurrency(product.price);
      return `${currencyAndPrice}/${t("ORDER_RECIPE.PRODUCT.MONTH")}`;
    }
    return "" as never;
  };

  const discountValue = computed<string>(() => {
    if (discountStore.isDiscountFullValue) return t("PAY.SP.PRODUCTS.FREE");
    return `-${formatPriceCurrency(discountStore.getDiscountVal(paymentStore.selectedProduct!))}`;
  });

  const firsthMonthDiscountedPrice = computed<string>(() => {
    return formatPriceCurrency(paymentStore.firsthMonthDiscountedPrice);
  });

  const firstMonthFinalPrice = computed<string>(() => {
    return formatPriceCurrency(paymentStore.firstMonthFinalPrice);
  });

  return {
    productPriceFormatted,
    deliveryPriceFormatted,
    productTitle,
    discountValue,
    firsthMonthDiscountedPrice,
    firstMonthFinalPrice,
    maxPossibleFullPriceCut,
    deliveryPriceFormatted1,
    totalPriceFormatted,
    formatPriceCurrency,
  };
}
