<script setup lang="ts">
import { useLegal } from "@/composables/useLegal";

const { linkTermsAndCond, linkImprint, linkPrivacy } = useLegal();
</script>

<template>
  <div class="terms-and-cond">
    <a :href="linkPrivacy" target="_blank" rel="noopener" data-link="privacy"
      ><span>{{ $t("PAY.LEGAL.PRIVACY") }}</span>
    </a>
    <a :href="linkImprint" target="_blank" rel="noopener" data-link="imprint">
      <span>{{ $t("PAY.LEGAL.IMPRINT") }}</span>
    </a>
    <a :href="linkTermsAndCond" target="_blank" rel="noopener" data-link="terms"
      ><span>{{ $t("PAY.LEGAL.TERMS_AND_COND") }}</span>
    </a>
  </div>
</template>

<style scoped lang="pcss">
.terms-and-cond {
  padding: 1.5em 0;
  margin: 0 auto;
  width: fit-content;
  display: flex;
  justify-content: center;
  & a {
    text-decoration: none;
  }
  & span {
    color: var(--clr-grey-2);
    font-size: 0.8rem;
    cursor: pointer;
    margin: 0 1em;
    text-decoration: underline;
    text-decoration-color: var(--clr-grey-3);
    text-underline-offset: 0.5em;
    display: block;
    text-align: center;
    &:hover {
      color: black;
      text-decoration-color: black;
    }
  }
}
</style>
