import { type ComputedRef, type Ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { QuestionType, USA_Height } from "@/types/questions";
import { useQuestionsStore } from "@/stores/questions";
import { useMainStore } from "@/stores/main";

export function useUSAQuestions(
  validationError: ComputedRef<boolean | { [key in USA_Height]: boolean }>,
  answer: Ref<string>,
  isQuestionErrored: (val: boolean) => boolean
) {
  const mainStore = useMainStore();
  const questStore = useQuestionsStore();
  const { activeQuestion, questionsWithUSAVariations } = storeToRefs(questStore);

  const usaIsHeightQuestion = computed<boolean>(() => {
    return activeQuestion.value.type === QuestionType.NUMBER_HEIGHT_USA;
  });

  const usaHeightQuestionValid = computed<boolean>(() => {
    return [
      !(validationError.value as { [key in USA_Height]: boolean }).feet,
      !(validationError.value as { [key in USA_Height]: boolean }).inches,
    ].every((v) => !!v);
  });

  const usaUpdateHeight = ({ val, type }: { val: string; type: USA_Height }) => {
    const feet = answer.value.split(`'`)[0] || "";
    const inches = answer.value.split(`'`)[1] || "";
    if (type === USA_Height.FEET) {
      answer.value = `${val}'${inches}`;
    } else if (type === USA_Height.INCHES) {
      answer.value = `${feet}'${val}`;
    }
  };

  const usaHeightQuestionErrors = (): { [key in USA_Height]: boolean } => {
    return {
      feet: isQuestionErrored((validationError.value as { [key in USA_Height]: boolean }).feet),
      inches: isQuestionErrored((validationError.value as { [key in USA_Height]: boolean }).inches),
    };
  };

  const usaVariation = computed<boolean>(() => {
    if (!mainStore.isLocationUSA) return false;
    return questionsWithUSAVariations.value.includes(activeQuestion.value.key);
  });

  return { usaIsHeightQuestion, usaHeightQuestionValid, usaUpdateHeight, usaHeightQuestionErrors, usaVariation };
}
