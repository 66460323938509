import { ref, reactive, watch, type Ref } from "vue";
import { axiosInstance } from "@/utils/api/axios_instance";
import { API } from "@/utils/api/api_paths";
import camelcaseKeys from "camelcase-keys";
import type { Medication } from "@/types/apiTypes";

export function useMedicationPredictions(inp: Ref<string>) {
  const predictions = reactive<{ list: Medication[]; totalCount: number }>({ list: [], totalCount: 0 });
  const selectedPredictions = ref<Medication[]>([]);

  watch(inp, (newVal) => {
    if (!newVal) return resetPredictions();
    updatePredictions(newVal);
  });

  const updatePredictions = async (inp: string) => {
    const [_err, data]: any = await fetchMedsByUserInput(inp);

    predictions.list = data.list;
    predictions.totalCount = data.totalCount;
  };

  const addPrediction = (pred: Medication) => {
    resetPredictions();

    if (selectedPredictions.value.some((sp) => sp.medicationName === pred.medicationName)) return;
    selectedPredictions.value.push(pred);
  };

  const removeSelectedPrediction = (clickedPred: Medication) => {
    selectedPredictions.value = selectedPredictions.value.filter(
      (sp) => sp.medicationName !== clickedPred.medicationName
    );
  };

  const fetchMedsByUserInput = async (inp: string) => {
    try {
      const res = await axiosInstance.get(API.MEDS, { params: { "filters[medication_name_i_cont]": inp } });
      const data: { totalCount: number; medications: Medication[] } = camelcaseKeys(res.data, { deep: true });
      return [null, { list: data.medications, totalCount: data.totalCount }];
    } catch (err: any) {
      console.error(`Error on /medications API`, err);
      return [true, null];
    }
  };

  const resetPredictions = () => {
    predictions.list = [];
    predictions.totalCount = 0;
  };

  const initializeSelectedPreds = (val: Medication[]) => {
    if (!val.length) return;
    selectedPredictions.value = val;
  };

  return { predictions, selectedPredictions, removeSelectedPrediction, addPrediction, initializeSelectedPreds };
}
