import { useLifestyleStore } from "@/stores/lifestyle";
import { BasicQuestionKey, LifestyleQuestionKey, OtherRouteKey, SectionKey } from "@/types/questions";
import { AnalitycsEvents, sendEvent, sendLeadEventAfterQuest } from "@/utils/analytics";

export const EventActions = {
  Loaded: "Quiz Loaded",
  Begin: "Begin Button Clicked",
  DOB: "DOB Submitted",
  Email: "Email Submitted",
  Complete: "Quiz Completed",
} as const;

export type EventAction = (typeof EventActions)[keyof typeof EventActions];

export const sendDataLayerEvent = (eventAction: EventAction, eventData?: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "bioniqQuiz",
    eventAction,
    ...(!!eventData && { eventData }),
  });
};

export const updateDataLayerGuard = (to: any, from: any) => {
  if (to.name === SectionKey.INTRO) sendDataLayerEvent("Quiz Loaded");
  if (from.name === SectionKey.INTRO) sendDataLayerEvent("Begin Button Clicked");
  if (from.name === BasicQuestionKey.DOB) sendDataLayerEvent("DOB Submitted");
  if (from.name === LifestyleQuestionKey.EMAIL) {
    const lifestyleStore = useLifestyleStore();
    sendDataLayerEvent("Email Submitted", lifestyleStore.email);
  }
  if (from.name === LifestyleQuestionKey.EMAIL && to.name === OtherRouteKey.LOADING) {
    sendDataLayerEvent("Quiz Completed");
    sendEvent(AnalitycsEvents.FINISH_QUEST);
    sendLeadEventAfterQuest();
  }

  return true;
};
