<script setup lang="ts">
import { ref } from "vue";
import TextInputBox from "@/components/ui/TextInputBox.vue";
import { usePhraseTranslationShortcuts } from "@/composables/usePhraseTranslationShortcuts";
import type { Medication } from "@/types/apiTypes";
import { useMedicationPredictions } from "@/composables/useMedicationsPredictions";

const props = defineProps<{
  selectedValues: Medication[];
}>();
const emit = defineEmits<{
  (e: "change", value: Medication[]): void;
}>();

const { phraseNrByRouteName } = usePhraseTranslationShortcuts();

const inputVal = ref("");
const errored = ref(false);

const { selectedPredictions, predictions, removeSelectedPrediction, addPrediction, initializeSelectedPreds } =
  useMedicationPredictions(inputVal);

const emitChange = (e: string) => {
  inputVal.value = e;
};

const selectPrediction = (pred: Medication) => {
  addPrediction(pred);
  inputVal.value = "";
};

const changeSelected = (fn: () => void) => {
  fn();
  emit("change", selectedPredictions.value);
};

initializeSelectedPreds(props.selectedValues);
</script>

<template>
  <div class="quest-text-pred-container">
    <div class="pred-text-input-cont">
      <TextInputBox
        :inputName="`question${phraseNrByRouteName}`"
        :labelText="$t(`QUESTION_${phraseNrByRouteName}.LABEL`)"
        :value="inputVal"
        :errored="errored"
        @update="emitChange"
        :autofocusOn="true"
      >
        <template v-slot:inputLogo>
          <div class="input-logo">
            <img src="@/assets/icons/search.svg" alt="search" />
          </div> </template
      ></TextInputBox>
    </div>
    <div v-if="predictions.totalCount" class="pred-list-cont">
      <div class="predictions-list">
        <div
          v-for="pred in predictions.list"
          :key="pred.medicationName"
          class="prediction"
          @click="changeSelected(selectPrediction.bind(null, pred))"
        >
          <div class="text">{{ pred.medicationName }}</div>
          <div class="border-bottom"></div>
        </div>
      </div>
    </div>
    <div v-if="selectedPredictions.length" class="pred-selected-container">
      <div v-for="sp in selectedPredictions" :key="sp.medicationName" class="selected-pred">
        <div class="text">{{ sp.medicationName }}</div>
        <div class="close-icon" @click="changeSelected(removeSelectedPrediction.bind(null, sp))">
          <img src="@/assets/icons/close-grey.svg" alt="close" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
@import "@/assets/styles/ui.css";
@import "@/assets/styles/layout.css";

.quest-text-pred-container {
  height: 100%;
  max-width: 30em;
  margin: 0 auto;
  display: grid;
  grid-template-rows: max-content 1fr;
  justify-items: center;
  align-items: baseline;
  position: relative;
}
.pred-text-input-cont {
  width: 100%;
}
.pred-list-cont {
  position: absolute;
  width: 100%;
  top: 4em;
}
.predictions-list {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  max-height: 20em;
  background-color: var(--clr-white-1);
  border-radius: 1em;
  overflow-y: scroll;
  box-shadow: 0px 0px 10px var(--clr-grey-light-2);
}


@mixin scrollbar-two .predictions-list, 1em, 5px;

.prediction {
  padding: 0.7em 2em 0.7em 2em;
  cursor: pointer;
  position: relative;

  & .text {
    color: var(--clr-grey-9);
  }

  & .border-bottom {
    position: absolute;
    width: calc(100% - 4em);
    border-bottom: 1px solid var(--clr-grey-light-1);
    left: 5%;
    bottom: 0;
  }
}
.prediction:hover {
  background-color: var(--clr-grey-1);
}
.pred-selected-container {
  height: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 170%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: center;
  align-items: flex-start;
  align-content: baseline;
}
@mixin scrollbar-one .pred-selected-container;

.selected-pred {
  height: max-content;
  padding: 0.7em;
  border-radius: 1.4em;
  border: 1px solid var(--clr-grey-1);
  background-color: var(--clr-white-1);
  width: fit-content;
  flex-wrap: wrap;
  min-width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 1em 1em 0;

  &:hover {
    border: 1px solid var(--clr-grey-12);
    & .text {
      color: black;
    }
    & .close-icon img {
      content: url("@/assets/icons/close-black.svg");
    }
  }
  & .text {
    color: var(--clr-grey-3);
    font-size: 0.9rem;
  }

  & .close-icon {
    width: 0.6em;
    height: 0.6em;
    cursor: pointer;
    display: flex;
    margin-left: 0.6em;
    flex-shrink: 0;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (--phone-width) {
  .pred-selected-container {
    width: 100%;
  }
}
</style>
