<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { YesNoOptions } from "@/utils/constants";

const { t } = useI18n();

const emit = defineEmits<{ (e: "change", value: number): void }>();

const props = defineProps<{
  selectedValue: number | null;
}>();

const handleClick = (value: number) => {
  emit("change", value);
};

const isOptionSelected = (id: number) => {
  return props.selectedValue ? id === props.selectedValue : false;
};
</script>

<template>
  <div class="yes-no-boxes">
    <div
      v-for="opt in YesNoOptions"
      :key="opt.id"
      :class="{ option: true, selected: isOptionSelected(opt.id) }"
      @click="handleClick(opt.id)"
      :data-value="opt.value"
    >
      {{ t(opt.phraseKey) }}
    </div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/layout.css";
@import "@/assets/styles/ui.css";

@mixin defaultOption;

.yes-no-boxes {
  display: flex;
  justify-content: center;
  & .option:first-of-type {
    margin-right: 1.6em;
  }
  & .option {
    height: 12.5em;
    width: 12.5em;
    border-radius: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (--tablet-width) {
  .yes-no-boxes {
    margin-top: 2em;
  }
}
@media (--phone-width) {
  .yes-no-boxes {
    & .option:first-of-type {
      margin-right: 0.5em;
    }
    & .option {
      height: 10em;
      width: 10em;
      @media (--small-phone-width) {
        height: 8em;
        width: 8em;
      }
    }
  }
}
</style>
