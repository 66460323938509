<script setup lang="ts">
import { computed } from "vue";
import TextInputBox from "@/components/ui/TextInputBox.vue";
import { isMobileBrowser } from "@/utils/functions";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { getAppLocale } from "@/utils/language";

const { t } = useI18n();
const route = useRoute();

const props = defineProps<{
  value: string;
  errored: boolean;
  usaVariation?: boolean;
}>();
const emit = defineEmits<{ (e: "change", value: string): void }>();

const emitChange = (value: string) => {
  emit("change", value);
};

const datepickerLabel = computed<string>(() => {
  if (props.usaVariation) return t("DATE.LABEL_US");
  return t("DATE.LABEL");
});

const formatDate = ({ day, month, year }: { day: string; month: string; year: string }) => {
  if (props.usaVariation) return `${month}/${day}/${year}`;
  return `${day}/${month}/${year}`;
};

const onUpdate = (date: Date) => {
  const day = (date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()).toString();
  const month = (date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();
  const value = formatDate({ day, month, year });
  emitChange(value);
};

const onTextInput = (value: string) => {
  emitChange([2, 5].includes(value.length) ? `${value}/` : value);
};

const startDate = new Date();
startDate.setFullYear(new Date().getFullYear() - 18);
</script>

<template>
  <Datepicker
    name="birthday"
    :enable-time-picker="false"
    text-input
    @update:model-value="onUpdate"
    :start-date="startDate"
    week-start="0"
    menu-class-name="dp-custom-menu"
    :flow="['year', 'month', 'calendar']"
    auto-apply
    :offset="0"
    position="left"
    :text-input-options="{ openMenu: !isMobileBrowser() }"
    six-weeks
    :locale="getAppLocale(route.query.lang as any)"
  >
    <template #trigger>
      <TextInputBox
        inputName="birthdate"
        :labelText="datepickerLabel"
        :value="props.value"
        :errored="errored"
        @update="onTextInput"
        :autofocusOn="true"
      >
        <template v-slot:inputLogo v-if="!isMobileBrowser()">
          <div class="input-logo">
            <img src="@/assets/icons/calendar.svg" alt="calendar" />
          </div>
        </template>
      </TextInputBox>
    </template>
  </Datepicker>
</template>

<style lang="pcss">
;
@import "@/assets/styles/layout.css";

.dp-custom-menu {
  box-shadow: 0 2px 4px -1px var(--clr-black-4), 0 4px 5px var(--clr-black-5), 0 1px 10px var(--clr-black-6);
  border: none;
  border-radius: 1em;

  & .dp__overlay {
    border-radius: 1em;
  }
  & .dp__button, & .dp__arrow_top, & .dp__arrow_bottom{
    display: none;
  }
  & .dp__month_year_select{
    pointer-events: none;
  }
}
</style>
