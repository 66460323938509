import { ProductCategory } from "@/types/apiTypes";

export const isCampaignActive = import.meta.env.VITE_CAMPAIGN_ACTIVE === "true";

export interface Campaign {
  code: string;
  value: number;
  type: "value" | "percentage";
  bgColor: string;
  textColor: string;
  campaignFor: { [key in ProductCategory]: boolean };
}

export interface CampaignTrackerType {
  newCampaign: Campaign;
  oldCampaign: Campaign;
}

export const CampaignTracker: CampaignTrackerType = {
  newCampaign: {
    code: "BF50",
    value: 50,
    type: "percentage",
    bgColor: "#021223",
    textColor: "white",
    campaignFor: {
      [ProductCategory.GO_SINGLE_PURCHASE]: false,
      [ProductCategory.GO_SUBSCRIPTION]: true,
    },
  },
  oldCampaign: {
    code: "welcome33",
    value: 33,
    type: "percentage",
    bgColor: "#8BE100",
    textColor: "black",
    campaignFor: {
      [ProductCategory.GO_SINGLE_PURCHASE]: false,
      [ProductCategory.GO_SUBSCRIPTION]: true,
    },
  },
};

export const ExcludedCountriesFromNewCampaign: string[] = [];
