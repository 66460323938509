import { useQuestionsStore } from "@/stores/questions";
import { event } from "vue-gtag";
import { getFirstLastName } from "./functions";
import { usePaymentStore } from "@/stores/payment";
import { useDiscountStore } from "@/stores/discount";
import type { PaymentMethodType } from "@/services/Stripe";
import { useMainStore } from "@/stores/main";
import { useSupplementsStore } from "@/stores/supplements";

export enum AnalitycsEvents {
  BEGIN = "add_to_cart",
  BEGIN_QUEST = "begin_questionnaire",
  BEGIN_BASICS_QUEST = "begin_basics_questionnaire",
  BEGIN_HEALTH_QUEST = "begin_health_concerns_questionnaire",
  BEGIN_LIFESTYLE_QUEST = "begin_lifestyle_questionnaire",
  FINISH_QUEST = "finish_questionnaire",
  BEGIN_CHECKOUT = "begin_checkout",
  LEAD = "lead_submit",
  PRODUCT_SELECT = "product_select",
  ADD_SHIPPING = "add_shipping_info",
  PAYMENT_SELECTED = "payment_selected",
  PURCHASE = "purchase",

  ABANDONED_CHECKOUT_RECOVERY = "abandoned_checkout_recovery",
  REDIRECTED_TO_LP = "redirected_to_lp",
}

const defaultParams = () => {
  const paymentStore = usePaymentStore();
  const mainStore = useMainStore();
  return {
    country_iso_code: paymentStore.selectedCountryCode.value,
    product_type: "GO",
    checkout_version: mainStore.checkoutVersion || "default",
    formula_presentation: mainStore.formulaPresentation,
  };
};

export function sendEvent(eventName: AnalitycsEvents, selectedPaymentMethod?: PaymentMethodType) {
  const params: any = { ...defaultParams(), ...productDetails() };

  const paymentStore = usePaymentStore();
  if (paymentStore.deliveryAddress.city) {
    params.city = paymentStore.deliveryAddress.city;
  }
  if (eventName === AnalitycsEvents.PURCHASE && !!selectedPaymentMethod) {
    params.payment_type = selectedPaymentMethod;
  }

  event(eventName, params);
}

function sendLeadEvent(customerInformation: any, isAfterQuest = false) {
  event(AnalitycsEvents.LEAD, {
    ...defaultParams(),
    type: isAfterQuest ? "go_questionnaire" : "checkout",
    first_name: customerInformation.firstName,
    last_name: customerInformation.lastName,
    phone: customerInformation.phoneNumber,
    email: customerInformation.email,
  });
}

export function sendLeadEventInPayment() {
  // don't send event if marketing consent was not selected
  const paymentStore = usePaymentStore();
  if (!paymentStore.marketingConsentSelected) return;
  sendLeadEvent(paymentStore.customerInformation);
}

export function sendLeadEventAfterQuest() {
  // don't send event if marketing consent was not selected
  const questionsStore = useQuestionsStore();
  if (!questionsStore.lifestyleStore.marketingConsentSelected) return;
  const info = {
    ...getFirstLastName(questionsStore.lifestyleStore.fullName),
    email: questionsStore.lifestyleStore.email,
  };
  sendLeadEvent(info, true);
}

function productDetails() {
  const paymentStore = usePaymentStore();
  const discountStore = useDiscountStore();
  const product = paymentStore.selectedProduct;
  if (!product) return {};

  const discountValue = discountStore.getDiscountVal(product);
  const shippingValue = paymentStore.spShippingZone!.deliveryPrice;
  const total = product.price - discountValue + shippingValue;
  return {
    currency: product.currency,
    value: total,
    shipping: shippingValue,
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        price: product.price,
        quantity: product.quantity,
        coupon: discountStore.code,
        discount: discountValue,
      },
    ],
  };
}

export function sendFormulaGuardEvents() {
  const mainStore = useMainStore();
  if (!mainStore.hubspotContactEmail) return false;

  const supplementStore = useSupplementsStore();

  if (
    supplementStore.supplementsFormula &&
    supplementStore.supplementsFormula.supplements &&
    supplementStore.supplementsFormula.supplements.length
  ) {
    sendEvent(AnalitycsEvents.ABANDONED_CHECKOUT_RECOVERY);
    return true;
  } else {
    sendEvent(AnalitycsEvents.REDIRECTED_TO_LP);
    return false;
  }
}
