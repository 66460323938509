import type { QuestionOption } from "@/types/uiTypes";
import { HealthConcernsOptions } from "./constants";

export const isDesktopBrowser = () => {
  return window.matchMedia("(min-width: 1201px)").matches;
};

export const isMobileBrowser = () => {
  return /Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent);
};

export const isMobilePortrait = () => {
  return isMobileBrowser() && window.matchMedia("(orientation: portrait)").matches && window.innerWidth <= 600;
};

export function isIOSMobileBrowser() {
  return /iPhone/i.test(navigator.userAgent) || (/Macintosh/.test(navigator.userAgent) && "ontouchend" in document);
}

export const findValueByIdInOptionsList = (list: QuestionOption[], id: number): string => {
  const option = list.find((item) => item.id === id);
  return option ? option.value : "";
};

export const findIdByValueInOptionsList = (list: QuestionOption[], value: string) => {
  return list.find((item) => item.value === value)!.id;
};

export function goToAndroidStore() {
  location.href = "https://play.google.com/store/apps/details?id=com.bioniq.health";
}

export function goToiOSStore() {
  location.href = "https://apps.apple.com/app/bioniq/id1558100209";
}

export function goToTermsAndConditions() {
  window.open("https://bioniq.com/en/legal");
}

export function getFirstLastName(fullname: string): { firstName: string; lastName: string } {
  const nameParts = fullname.split(" ");
  return {
    lastName: nameParts.pop() || "",
    firstName: nameParts.join(" ") || "",
  };
}

export function getCookie(cname: string): string {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (const character of cookieArray) {
    const c = character.trim();
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
}

// Extracts the UTM parameters from URL or Cookie
export function getUTMFromURLOrCookie(query: any) {
  return {
    utmSource: query.utm_source || query["utm-source"] || getCookie("utm_source"),
    utmMedium: query.utm_medium || query["utm-medium"] || getCookie("utm_medium"),
    utmCampaign: query.utm_campaign || query["utm-campaign"] || getCookie("utm_campaign"),
    utmContent: query.utm_content || query["utm-content"] || getCookie("utm_content"),
    utmTerm: query.utm_term || query["utm-term"] || getCookie("utm_term"),
  };
}

export function hasAnswer(answer: any) {
  return Array.isArray(answer) ? answer.length > 0 : true;
}

export const entries_unsafe = Object.entries as <T>(obj: T) => Array<[keyof T, T[keyof T]]>;

export function concernKeysToOptionsLimited(goalKeys: string[]): QuestionOption[] {
  return goalKeys && Array.isArray(goalKeys)
    ? goalKeys.slice(0, 3).map((goalKey) => HealthConcernsOptions.find((goal) => goal.value === goalKey)!)
    : [];
}

export function sanitizeUrlFromQueryParamsWithNoValue(url: string) {
  const cleanUrl = new URL(url);

  for (const [key, value] of new URLSearchParams(cleanUrl.searchParams)) {
    if (!value) cleanUrl.searchParams.delete(key);
  }
  return cleanUrl.href;
}

export function addQueryParamToUrl(urlString: string, param: { key: string; value: string }) {
  const url = new URL(urlString);
  url.searchParams.delete(param.key);
  url.searchParams.append(param.key, param.value);
  return url.href;
}
