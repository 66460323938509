<script setup lang="ts">
import { reactive } from "vue";
import { RouterView } from "vue-router";

const appStatus = reactive({
  error: false,
  loading: false,
});
</script>

<template>
  <main>
    <RouterView v-if="!appStatus.loading && !appStatus.error" />
    <div v-if="appStatus.loading">Loading...</div>
    <div v-if="appStatus.error">Error</div>
  </main>
</template>

<style scoped lang="pcss">
main {
  height: inherit;
}
</style>
