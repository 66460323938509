import { type QuestionKey, BasicQuestionKey, type QuestionCheckbox, LifestyleQuestionKey } from "@/types/questions";
import moment from "moment";
import isEmail from "validator/es/lib/isEmail";
import { RegExpMatcher, englishDataset, englishRecommendedTransformers } from "obscenity";

export const validateTextInput = (val: string, qKey: QuestionKey) => {
  switch (qKey) {
    case LifestyleQuestionKey.FULL_NAME:
      return validateFullName(val);
    case LifestyleQuestionKey.EMAIL:
      return validateEmail(val);
    default:
      break;
  }
  return !!val;
};

export const validateBirthDate = (val: string, usaStyle: boolean) => {
  const format = usaStyle ? "MM/DD/YYYY" : "DD/MM/YYYY";
  const date = moment(val, format, true);
  // date cannot be in the future
  if (date.isAfter(moment())) return false;
  // age cannot be over 100
  if (moment().diff(moment(date, "DD/MM/YYYY"), "years") > 100) return false;
  return date.isValid();
};

const validateFullName = (val: string) => {
  const trimmedVal = val.trim();
  const regex = /^[a-zA-Z0-9\s'-]+$/;
  return trimmedVal.includes(" ") && regex.test(trimmedVal);
};

const hasProfanities = (val: string) => {
  const matcher = new RegExpMatcher({
    ...englishDataset.build(),
    ...englishRecommendedTransformers,
  });

  const allowed = ["dick"]; // from American name Richard
  return matcher.hasMatch(val) && !allowed.includes(val.toLowerCase());
};

export const validateNameBottle = (val: string) => {
  if (!val) return false;
  const trimmedVal = val.trim();
  const regex = /^[a-zA-Z0-9\s'-]+$/;
  return !hasProfanities(trimmedVal) && regex.test(trimmedVal);
};

export const validateEmail = (val: string) => {
  return isEmail(val);
};

export const validateNumber = (val: string, qKey: QuestionKey, usaStyle: boolean = false) => {
  if (!isNumeric(val)) return false;
  const intVal = parseInt(val);
  switch (qKey) {
    case BasicQuestionKey.HEIGHT:
      return validateHeight(intVal);
    case BasicQuestionKey.WEIGHT:
      return validateWeight(intVal, usaStyle);
    case BasicQuestionKey.PREGNANCY_WEEKS:
      return validatePregnancyWeeks(intVal);
    default:
      break;
  }
  return !!val;
};

export const validateHeightFeet = (val: string) => {
  const feet = parseInt(val.split(`'`)[0]);
  return feet >= 2 && feet <= 8;
};

export const validateHeightInches = (val: string) => {
  const inches = parseInt(val.split(`'`)[1]);
  return inches >= 0 && inches < 12;
};

const isNumeric = (val: string) => {
  return /^\d+$/.test(val);
};

const validateHeight = (val: number) => {
  return val > 50 && val < 250;
};

const validateWeight = (val: number, usaStyle: boolean) => {
  if (usaStyle) {
    return val > 22 && val < 550;
  } else {
    return val > 10 && val < 250;
  }
};

const validatePregnancyWeeks = (val: number) => {
  return val > 0 && val < 46;
};

export const validateCheckbox = (values: any, question: QuestionCheckbox) => {
  if (!values) return false;
  if (!values.length) return false;
  if (question.max && values.length > question.max) return false;
  return true;
};
