import type { OrderSubmit } from "@/types/apiTypes";
import type {
  Stripe,
  StripeElements,
  StripePaymentElement,
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
  DefaultValuesOption,
} from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import snakecaseKeys from "snakecase-keys";
import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import { API } from "@/utils/api/api_paths";
import { isMobileBrowser } from "@/utils/functions";

export interface StripeBillingDetails {
  name: string;
  email: string;
  phone: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    state?: string;
    country: string;
    postal_code: string;
  };
}
export interface StripeOptions {
  clientSecret: string;
  appearance: any;
}

export enum PaymentMethodType {
  CARD = "card",
  SEPA = "sepa_debit",
}

const getCSSOptions = () => {
  const appearance = {
    theme: "none",
    labels: "resting",
    variables: {
      colorText: "#829099",
      fontSizeBase: "1.1rem",
      borderRadius: "0.75em",
    },
    rules: {
      ".Input": {
        outline: "1px solid #E2E9EE",
        padding: "1.5em 0.75em",
        backgroundColor: "#F6F8FB",
      },
      ".Input:focus": {
        outline: "1px solid black",
      },
      ".Input::placeholder": {
        color: "#b5bec4",
      },
      ".Block": {
        outline: "1px solid #E2E9EE",
        backgroundColor: "#F6F8FB",
      },
      ".CodeInput": {
        outline: "1px solid #E2E9EE",
        backgroundColor: "white",
      },
      ".CodeInput:focus": {
        outline: "1px solid black",
      },
      ".Tab": {
        outline: "1px solid #E2E9EE",
      },
      ".Tab--selected": {
        outline: "1px solid black",
      },
    },
  };
  if (isMobileBrowser()) {
    appearance.variables.fontSizeBase = "1rem";
    appearance.rules[".Input"].padding = "1em 0.5em";
  }
  return appearance;
};

export const StripeService = {
  stripe: null as null | Stripe,
  elements: null as null | StripeElements,
  paymentElement: null as null | StripePaymentElement,
  elementsOptions: {
    clientSecret: "",
    appearance: getCSSOptions(),
  } as StripeOptions,
  paymentElementOptions: {
    defaultValues: null as null | StripeBillingDetails,
    fields: { billingDetails: "never" },
  } as StripePaymentElementOptions,
  cardElementsIds: {
    payment: "",
    error: "",
  },
  callbacks: {
    onSuccess: null as (({ free, secret }: { free: boolean; secret: string }) => void) | null,
    onError: null as ((text: string) => void) | null,
    uiUpdate: null as (() => void) | null,
  },
  listened: {
    selectedPaymentMethodType: PaymentMethodType.CARD as PaymentMethodType,
  },

  setCardElementsIds(paymentEl: string, errorEl: string) {
    this.cardElementsIds.payment = paymentEl;
    this.cardElementsIds.error = errorEl;
  },

  setCallbacks(
    successCb: ({ free, secret }: { free: boolean; secret: string }) => void,
    errorCb: (text: string) => void,
    uiCb: () => void
  ) {
    this.callbacks.onSuccess = successCb;
    this.callbacks.onError = errorCb;
    this.callbacks.uiUpdate = uiCb;
  },

  setBillingDetails(billingDetails: DefaultValuesOption) {
    this.paymentElementOptions.defaultValues = billingDetails;
  },

  resetData() {
    this.stripe = null;
    this.elements = null;
    this.elementsOptions.clientSecret = "";
    this.cardElementsIds.payment = "";
    this.cardElementsIds.error = "";
    this.callbacks.onSuccess = null;
    this.callbacks.onError = null;
    this.callbacks.uiUpdate = null;
  },

  async loadStripeSDK(apiKey: string) {
    return await loadStripe(apiKey);
  },

  async initializeStripe(apiKey: string) {
    try {
      this.stripe = await loadStripe(apiKey);
      this.elements = this.stripe!.elements(this.elementsOptions as any);
      return [null, true];
    } catch (err) {
      console.error(`Error trying to load the Stripe SDK, `, err);
      this.onLoadError();
      return [true, null];
    }
  },

  initializePayment() {
    this.paymentElement = this.elements!.create("payment", this.paymentElementOptions);
    this.paymentElement.mount(`#${this.cardElementsIds.payment}`);

    this.attachEventsListeners();
  },

  onChangeEvent(e: StripePaymentElementChangeEvent) {
    this.listened.selectedPaymentMethodType = e.value.type as PaymentMethodType;
  },

  onLoadError() {
    this.callbacks.onError!("PAY.CARD.ERROR");
  },

  attachEventsListeners() {
    this.paymentElement!.on("change", this.onChangeEvent.bind(this));
    this.paymentElement!.on("loaderror", this.onLoadError.bind(this));
  },

  async getClientSecret(order: OrderSubmit) {
    const snakedOrder = snakecaseKeys(order);
    try {
      const res: any = await axios.post(`${API.CLIENT_SESSION}`, snakedOrder);
      const result = camelcaseKeys(res.data);
      const token = result.clientSecret;

      if (token) this.elementsOptions.clientSecret = token;
      return [null, token];
    } catch (err: any) {
      console.error("Error on /submit request, ", err);
      const errorMsg = err.response?.data?.error || err.message;
      return [errorMsg, null];
    }
  },

  async confirmPayment() {
    const { error, paymentIntent } = await this.stripe!.confirmPayment({
      elements: this.elements!,
      confirmParams: {
        return_url: `${import.meta.env.VITE_DOMAIN}/thank-you?secret=${this.elementsOptions.clientSecret}`,
        payment_method_data: {
          billing_details: this.paymentElementOptions.defaultValues?.billingDetails,
        },
      },
      redirect: "if_required",
    });

    if (error) {
      console.error("Error on stripe.confirmPayment SDK API, ", error);
      this.callbacks.uiUpdate!();
      if (error.type === "card_error" && error.code) {
        this.callbacks.onError!("PAY.CARD.ERROR_DECLINED_PAYMENT");
        return [true, null];
      }
      this.callbacks.onError!("PAY.CARD.ERROR_FAILED_PAYMENT");
    } else {
      this.callbacks.onSuccess!({ free: false, secret: paymentIntent!.client_secret! });
      return [null, true];
    }
  },
};
