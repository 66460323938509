<script setup lang="ts">
import { computed } from "vue";
import type { Question } from "@/types/questions";
import { useQuestionsStore } from "@/stores/questions";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

enum ProgressSectionKey {
  HEALTH = "section-health",
  BASICS = "section-basics",
  LIFESTYLE = "section-lifestyle",
}

const route = useRoute();
const questStore = useQuestionsStore();
const { currentStepNumber, activeQuestionsNumber } = storeToRefs(questStore);

const sectionWidth = computed<string>(() => {
  return `${100 / Object.values(ProgressSectionKey).length - 1}%`;
});

const sectionBg = (sectionKey: ProgressSectionKey) => {
  const secStore = questStore.storeForSection(sectionKey);
  let totalActiveQuestionsPerSection = secStore.questions.reduce((total: number, q: Question) => {
    if (q.active) total += 1;
    return total;
  }, 0);

  let currQuestIndex = secStore.questions
    .filter((q: Question) => !!q.active)!
    .findIndex((q: Question) => q.key === route.meta.qKey);

  if (route.meta.sKey === sectionKey) {
    currQuestIndex += 1;
    totalActiveQuestionsPerSection += 1;
  }

  let percentage = (currQuestIndex * 100) / totalActiveQuestionsPerSection;
  if (
    Object.values(ProgressSectionKey).indexOf(sectionKey) <
    Object.values(ProgressSectionKey).indexOf(route.meta.sKey as ProgressSectionKey)
  )
    percentage = 100;

  return `linear-gradient(to right, var(--clr-grey-16) ${percentage}%, white 0%)`;
};
</script>

<template>
  <div class="progress-bar-container">
    <div class="line-container">
      <div
        v-for="section in Object.values(ProgressSectionKey)"
        :key="section"
        class="section"
        :style="{ background: sectionBg(section as ProgressSectionKey), width: sectionWidth }"
      ></div>
    </div>
    <div class="text-container">{{ currentStepNumber }} / {{ activeQuestionsNumber }}</div>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/styles/layout.css";
.progress-bar-container {
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: fit-content;
}
.line-container {
  width: 20em;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.text-container {
  margin-left: 1em;
}
.section {
  background-color: white;
  border-radius: 0.15em;
  height: 0.3em;
}

@media (--phone-width) {
  .progress-bar-container {
    margin: 0;
  }
  .line-container {
    width: 15em;
  }
}
</style>
