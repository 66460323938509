import { fetchLocale, addLanguageToI18N, changeAppLocale } from "@/includes/i18n-setup";
import { LOCALE } from "@/utils/constants";

export const getBrowserLocale = () => {
  return navigator.language.split("-")[0].toLowerCase() as LOCALE;
};

export const changeLanguage = async (newLang: LOCALE) => {
  const res = await fetchLocale(newLang);
  if (!res) throw new Error();

  addLanguageToI18N(newLang, res);
  changeAppLocale(newLang);
  return;
};

export const getAppLocale = (queryLocale: string | undefined) => {
  if (queryLocale === "de") return LOCALE.DE;
  return LOCALE.EN;
};
